import { ref, reactive, watch } from 'vue';
import { defineStore } from 'pinia';
import Dexie from 'dexie';

const validTypes = [ 'WMS', 'WFS', 'WMTS' ];

export const useLayersStore = defineStore('layers', () => {
	const dbContainer = reactive({});
	const listeners = ref([]);
	const count = ref(0);

	function initializeDB () {
		dbContainer.db = new Dexie('MPADMIN-layers');
		dbContainer.db.version(1).stores({
			rawLayerList: '&id, typ, source',
			lightLayerList: '&id, name, source',
		});
	}

	async function loadLayersRaw (layerConf) {
		await dbContainer.db.rawLayerList.clear();
		await dbContainer.db.rawLayerList.bulkPut(layerConf
			.map(layer => ({
				...layer,
				source: 'default',
			})));
	}
	async function loadLayersLight (layerConf) {
		await dbContainer.db.lightLayerList.clear();
		await dbContainer.db.lightLayerList.bulkPut(layerConf
			.filter(layer => layer.name && validTypes.includes(layer.typ))
			.map(({ id, name, typ, url, version, featureType }) => ({
				id,
				name,
				typ,
				url,
				version,
				featureType,
				unmanaged: JSON.stringify({}),
				source: 'default',
			})));
	}
	async function loadLayers (layerConf) {
		await Promise.all([
			loadLayersRaw(layerConf),
			loadLayersLight(layerConf),
		]);
		count.value = await dbContainer.db.lightLayerList.count();
		listeners.value.forEach(listener => {
			listener();
		});
	}
	function onLoadLayers (listener) {
		listeners.value.push(listener);
	}

	async function getLayerDetails (ids) {
		const layers = await dbContainer.db.rawLayerList.where('id').anyOf(ids).toArray();
		return layers;
	}
	async function getLayerDetail (id) {
		const [ layer ] = await getLayerDetails([ id ]);
		return layer;
	}

	async function getLightLayers () {
		return await dbContainer.db.lightLayerList.toArray();
	}
	async function getSortedLightLayers ({ filter } = {}) {
		let { lightLayerList } = dbContainer.db;
		switch (filter) {
			case 'no-group':
				lightLayerList = lightLayerList.where('source').noneOf([ 'grouplayer' ]);
				break;
			default: // no filter
				break;
		}
		const lightLayers = await lightLayerList.toArray();
		return lightLayers.sort(({ name: a }, { name: b }) => a.localeCompare(b, 'de', { sensitivity: 'base' }));
	}

	const overrideLayers = ref([]);
	watch(overrideLayers, async rawLayers => {
		const layers = rawLayers.filter(({ name, url }) => name && url);
		const lightAttributes = [
			'id',
			'name',
			'typ',
		];
		function mapLightAttributes (layer) {
			return Object.fromEntries(lightAttributes.map(key => ([ key, layer[key] ])));
		}
		function mapAttributes (layer) {
			return {
				featureCount: '1',
				format: 'image/png',
				gfiAttributes: 'ignore',
				gfiTheme: 'default',
				tilesize: 512,
				transparent: true,
				transparency: 0,
				...Object.fromEntries(Object.entries(layer).filter(([ key ]) => !key.startsWith('mpadmin'))),
			};
		}
		await Promise.all([
			(async () => {
				// Deletion may throw errors when nothing is there to delete, catch this.
				try {
					await dbContainer.db.lightLayerList.where({ source: 'layeroverride' }).delete();
				} catch (e) { /**/ }
				await dbContainer.db.lightLayerList.bulkPut(layers.map(layer => ({
					...mapLightAttributes(layer),
					unmanaged: JSON.stringify(mapAttributes(layer)),
					source: 'layeroverride',
				})));
			})(),
			(async () => {
				// Deletion may throw errors when nothing is there to delete, catch this.
				try {
					await dbContainer.db.rawLayerList.where({ source: 'layeroverride' }).delete();
				} catch (e) { /**/ }
				await dbContainer.db.rawLayerList.bulkPut(layers.map(layer => ({
					...mapLightAttributes(layer),
					...mapAttributes(layer),
					source: 'layeroverride',
				})));
			})(),
		]);
	}, { deep: true });

	async function clearGroupLayers () {
		// Deletion may throw errors when nothing is there to delete, catch this.
		try {
			await dbContainer.db.rawLayerList.where({ source: 'grouplayer' }).delete();
		} catch (e) { /**/ }
	}
	async function getGroupLayers () {
		const layers = await dbContainer.db.rawLayerList.where({ source: 'grouplayer' }).toArray();
		return Promise.all(layers.map(async ({ id, name, children }) => ({
			id,
			name,
			children: await Promise.all(children.map(async ({ id: childId, ...unmanaged }) => {
				const layer = await dbContainer.db.rawLayerList.get(childId);
				return {
					id: childId,
					name: layer.name,
					...unmanaged,
				};
			})),
		})));
	}
	async function putGroupLayer (layer) {
		const model = {
			...layer,
			children: layer.children?.map(({ id }) => ({ id })) ?? [],
			source: 'grouplayer',
		};
		const { id, name, source, ...unmanaged } = model;
		await Promise.all([
			dbContainer.db.lightLayerList.put({
				id,
				name,
				unmanaged: JSON.stringify(unmanaged),
				source,
			}),
			dbContainer.db.rawLayerList.put(model),
		]);
	}
	async function dropGroupLayer (id) {
		await Promise.all([
			dbContainer.db.lightLayerList.delete(id),
			dbContainer.db.rawLayerList.delete(id),
		]);
	}

	return {
		initializeDB,
		loadLayers,
		onLoadLayers,
		getLayerDetails,
		getLayerDetail,
		getLightLayers,
		getSortedLightLayers,
		count,

		overrideLayers,

		clearGroupLayers,
		getGroupLayers,
		putGroupLayer,
		dropGroupLayer,

		_persistedState: [ 'overrideLayers' ],

		// exported for testing purposes only
		dbContainer,
	};
});
