import { ref, watch } from 'vue';
import Dexie from 'dexie';

const configStorage = {
	async get (id) {
		const result = await this.db.entry.get(id);
		return result ? JSON.parse(result.value) : result;
	},
	async set (id, value) {
		await this.db.entry.put({ id, value: JSON.stringify(value) });
	},
};

configStorage.db = new Dexie('MPADMIN-config');
configStorage.db.version(1).stores({
	entry: '&id, value',
});

export default async function ({ store }) {
	if (!store._persistedState) {
		return;
	}

	const localStorageKey = `mp-admintool-${store.$id}`;
	const lastModKey = `mp-admintool_lastmod-${store.$id}`;
	store._lastModified = ref(Number(localStorage.getItem(lastModKey)));

	// Legacy code to migrate old localStorage information
	if (localStorage.getItem(localStorageKey)) {
		const savedData = JSON.parse(localStorage.getItem(localStorageKey));
		await configStorage.set(store.$id, savedData);
	}

	const loadedData = await configStorage.get(store.$id);
	if (loadedData) {
		Object.entries(loadedData).forEach(([ key, value ]) => {
			store[key] = value;
		});
	}

	watch(store._persistedState.map(key => () => store[key]), async ([ ...persistedState ]) => {
		await configStorage.set(store.$id, Object.fromEntries(store._persistedState.map((key, i) => ([ key, persistedState[i] ]))));
		store._lastModified = new Date().getTime();
		localStorage.setItem(lastModKey, store._lastModified);
	}, { deep: true });
}
