import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useExpertModeStore = defineStore('expertMode', () => {
	const expertMode = ref(false);
	function setExpertMode (value) {
		expertMode.value = value;
	}

	return {
		expertMode,
		setExpertMode,
	};
});
