/**
 * Checks whether an object is a candidate to actually be a config.json file.
 * Just a rough check whether user misclicked when uploading.
 * @param {object} config object to check
 * @returns {boolean} whether object seems fine at first glance
 */
export function looksLikeConfigJson (config) {
	return typeof config === 'object' && typeof config.Portalconfig === 'object' && typeof config.Themenconfig === 'object';
}

/**
 * Tests whether a string describes an integer.
 * That is, only a full number is allowed.
 * @param {string} s string to test
 * @returns {boolean} whether string represents integer
 */
export function stringIsInteger (s) {
	return (/^-?\d*$/).test(s);
}

/**
 * Tests whether a string describes a float.
 * That is, full numbers, optionally with decimals, are allowed.
 * @param {string} s string to test
 * @returns {boolean} whether string represents float
 */
export function stringIsFloat (s) {
	return (/^-?\d*\.?\d*$/).test(s);
}

/**
 * Tests whether a string is a valid URL with the given regex matchpattern
 * @param {string} string string to test
 * @returns {boolean} whether string represents a valid URL
 */
export function isValidUrl (string) {
	const matchpattern = /^https?:\/\/(?:\w+:?\w*)?\S+(:[0-9]+)?(\/|\/([\w#!:.?+=&%@\-/]))?$/gm;
	return matchpattern.test(string);
}
