import { useConfigStore } from '@/store/config';
import { useLayersStore } from '@/store/layers';
import { getBasePath } from '@/lib/basePath';
import { crs, setBackgroundImage } from '@masterportal/masterportalapi';
import api from '@masterportal/masterportalapi/src/maps/api';
import createStyle from '@masterportal/masterportalapi/src/vectorStyle/createStyle';

export default async function showMap (layerId, styleId, targetEpsg, targetName = 'map') {
	const config = useConfigStore();
	const layersStore = useLayersStore();

	await new Promise(resolve => config.onInitialized(resolve));
	const layerIds = config.masterportalApiConfig.layers.map(({ id }) => id);
	if (layerId) {
		layerIds.push(layerId.toString());
	}
	const layerData = await layersStore.getLayerDetails(layerIds);
	const layers = layerIds.map(id => layerData.find(layer => layer.id === id));
	if (layerId && styleId) {
		const layer = layers[layers.length - 1];
		const styleObject = config.styleConf.find(it => it.styleId === styleId);
		layer.style = feature => {
			const isClusterFeature = typeof feature.get('features') === 'function' || typeof feature.get('features') === 'object' && Boolean(feature.get('features'));
			return createStyle.createStyle(styleObject, feature, isClusterFeature, config.masterportalApiConfig.wfsImgPath);
		};
	}

	const mapConfig = {
		...config.masterportalApiConfig,
		target: targetName,
		layerConf: layers,
	};
	if (targetEpsg !== mapConfig.epsg) {
		const [ left, top ] = crs.transform(mapConfig.epsg, targetEpsg, [ mapConfig.extent[0], mapConfig.extent[3] ]);
		const [ right, bottom ] = crs.transform(mapConfig.epsg, targetEpsg, [ mapConfig.extent[2], mapConfig.extent[1] ]);
		const startCenter = crs.transform(mapConfig.epsg, targetEpsg, mapConfig.startCenter);
		mapConfig.extent = [ left, bottom, right, top ];
		mapConfig.startCenter = startCenter;
		mapConfig.epsg = targetEpsg;
	}

	const map = api.map.createMap(mapConfig);
	setBackgroundImage({
		backgroundImage: `${getBasePath()}/assets/images/backgroundCanvas.jpeg`,
		target: mapConfig.target,
	});

	if (layerId) {
		map.addLayer(layerId.toString());
	}
	return map;
}
