<script setup>
import { computed } from 'vue';
import { useLoadingStore } from '@/store/loading';

const loading = useLoadingStore();

/** Global loading indicator to be controlled by store interactions. May be controlled from anywhere on demand. */
const value = 1;
const indeterminate = true;
const active = computed(() => loading.loading);
</script>

<template lang="pug">
v-progress-linear(
	background-color="#E3E3E3AA"
	color="#003063AA"
	:model-value="value"
	:active="active"
	:indeterminate="indeterminate"
	:query="true"
)
</template>

<style lang="scss" scoped>
@import '../globals.scss';

/* stick to page top */
.v-progress-linear {
	margin: 0;
	position: fixed;
	top: 0;
}
</style>
