/**
 * Checks the whole graph recursively for labels which should be renamed due to configured rename matrix
 * @param {*} item the actual item which is checked if a existing label has to be renamed
 * @param {Object} renameMatrix rename matrix
 *
 * @returns {void}
 */
export function renameLabels (item, renameMatrix) {
	if (item && typeof item === 'object' && !Array.isArray(item)) {
		if (item.hasOwnProperty('label')) {
			Object.entries(renameMatrix).forEach((find) => {
				item.label = item.label.replace(new RegExp(`^${find[0]}$`), find[1]);
			});
		}
		Object.entries(item).forEach(entry => {
			renameLabels(entry, renameMatrix);
		});
	}
	if (Array.isArray(item)) {
		item.forEach(entry => {
			renameLabels(entry, renameMatrix);
		});
	}
}
