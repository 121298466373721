<script setup>
/* eslint-disable */
/* TODO: Remove this file when v-treeview is released into vuetify 3. This is an ugly hack. */
const props = defineProps([
	'open',
	'items',
]);
const emit = defineEmits([
	'update:open',
]);

import { computed } from 'vue';
const open = computed({
	get: () => props.open,
	set: value => {
		emit('update:open', value);
	},
});
</script>

<template lang="pug">
v-list(v-model:opened="open")
	a-treeview-item(
		v-for="item of props.items"
		:item="item"
	)
		template(#label="props")
			slot(name="label" v-bind="props")
</template>
