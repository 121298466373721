/**
 * Checks an value if it should be interpreted as true (not necessarily truthy)
 * e.g.: "1" and "true" should be true; but "false" and "0" should be false
 * @param {*} switchValue - Value to be tested
 * @returns {Boolean} True if the provided value should be interpreted as true (not necessarily truthy)
 */
export function switchIsON (switchValue) {
	if (!switchValue) {
		return false;
	}
	return [ 1, '1', true, 'true', 'yes' ].includes(typeof switchValue === 'string' ? switchValue.toLowerCase() : switchValue);
}
