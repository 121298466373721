import i18next from 'i18next';
import I18NextVue from 'i18next-vue';

import englishTranslation from '@/locales/en';
import germanTranslation from '@/locales/de';

i18next.init({
	lng: (new URLSearchParams(window.location.search)).get('language') || 'de',
	fallbackLng: 'en',
	resources: {
		en: { translation: englishTranslation },
		de: { translation: germanTranslation },
	},
});

export default {
	install: app => {
		app.use(I18NextVue, { i18next });
	},
};
