<script setup>
import { ref, watch } from 'vue';
import { usePreviewStore } from '@/store/preview';
import { usePageLayoutStyleStore } from '@/store/pageLayoutStyle';
import showMap from '@/lib/showMap';

const store = usePreviewStore();
const pageLayoutStyle = usePageLayoutStyleStore();

const mapWindow = ref(null);
const mapRef = ref(null);

function hideMap () {
	while (mapRef.value.firstChild) {
		mapRef.value.removeChild(mapRef.value.firstChild);
	}
}

watch([
	() => store.enabled,
	() => store.layerId,
	() => store.styleId,
	() => store.epsgCode,
], ([
	enabled,
	layerId,
	styleId,
	epsgCode,
], [
	oldEnabled,
	oldLayerId,
	oldStyleId,
	oldEpsgCode,
]) => {
	if (enabled) {
		pageLayoutStyle.setStyleName('overlay');
		showMap(layerId, styleId, epsgCode);
	} else if (oldEnabled) {
		pageLayoutStyle.setStyleName('default');
		hideMap();
	}
});

/* TODO: This seems to be a nice hack.
Migrate it.

	created () {
		/* Guard to prevent invisible navigation 'behind' the map; instead, when the map is visible
		 * and the browser's back action is performed, navigation is suppressed and map is hidden. *
		this.$router && this.$router.beforeEach((to, from, next) => {
			const mapIsVisible = this.$refs.mapWindow.style.display === 'block';
			if (mapIsVisible) {
				this.hideMap();
			}
			// calling next with false prevents navigation
			next(!mapIsVisible);
		});
*/
</script>

<template lang="pug">
#mapWindow(ref="mapWindow" :style="pageLayoutStyle.overlayWindowStyle")
	.controls
		v-btn.close(@click="store.enabled = false") {{ $t('controls.map.closePreview') }}
	#map(ref="mapRef")
</template>

<style lang="scss" scoped>
@import '../globals.scss';

#mapWindow {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 2;
	display: none;
}

#mapWindow .controls {
	position: fixed;
	z-index: 4;
	top: 0.5em;
	right: 0.5em;
}

#map {
	width: 100%;
	height: 100%;
	background-repeat: repeat;
}
</style>
