import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import { useGraphStore } from '@/store/graph';

export const usePreviewStore = defineStore('preview', () => {
	const graph = useGraphStore();

	const enabled = ref(false);
	const layerId = ref('');
	const styleId = ref(null);
	const epsgCode = computed(() => graph.config?.Portalconfig?.mapView?.epsg || graph.graph?.nodes?.['Portalconfig.mapView']?.outgoing?.epsg?.default);
	const extent = computed(() => graph.config?.Portalconfig?.mapView?.extent || graph.graph?.nodes?.['Portalconfig.mapView']?.outgoing?.extent?.default);

	function displayMap (params) {
		layerId.value = params.layerId;
		styleId.value = params.styleId;
		enabled.value = true;
	}

	return {
		enabled,
		layerId,
		styleId,
		epsgCode,
		extent,
		displayMap,
	};
});
