<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useExpertModeStore } from '@/store/expertMode';
import { useConfigStore } from '@/store/config';

const route = useRoute();
const store = useExpertModeStore();
const config = useConfigStore();
const displayCheckbox = computed(() => config.admintoolConfig.expertMode && route.path === '/editConfig/Portalconfig');
const expertMode = computed({
	get: () => store.expertMode,
	set: store.setExpertMode,
});
</script>

<template lang="pug">
v-checkbox(
	v-if="displayCheckbox"
	v-model="expertMode"
	:label="$t('controls.expertMode.label')"
	:title="$t('controls.expertMode.title')"
).expertCheckbox
</template>

<style lang="scss" scoped>
@import '../globals.scss';

.expertCheckbox {
	position: absolute;
	top: 60px;
	right: 24px;
	z-index: 1;
}
</style>
