<script setup>
const props = defineProps({
	activeStep: {
		type: Number,
		required: true,
	},
	steps: {
		type: Number,
		required: true,
	},
});

import { provide, toRefs } from 'vue';

const { activeStep, steps } = toRefs(props);
provide('AStepper-activeStep', activeStep);
provide('AStepper-steps', steps);
</script>

<template lang="pug">
v-list.stepper
	slot
</template>

<style lang="scss" scoped>
.stepper {
	min-height: 100%;
	min-width: 250px;
	border-radius: 0;
	overflow-y: auto;
	overflow-x: hidden;
}
</style>
