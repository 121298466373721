import { ref, reactive, watch, computed } from 'vue';
import { defineStore } from 'pinia';
import { useLayersStore } from '@/store/layers';
import { useGraphStore } from '@/store/graph';
import { useLoadingStore } from '@/store/loading';
import { useAlertStore } from '@/store/alert';
import { getBasePath } from '@/lib/basePath';
import { devModeFlag, useLocalConfig } from '@/lib/devModeFlag';
import filterMasterportalTags from '@/lib/filterMasterportalTags';
import { getHostedMasterportalVersion } from '@/lib/getHostedMasterportalVersion';
import { isValidUrl } from '@/lib/validators';

import axios from 'axios';
import { crs } from '@masterportal/masterportalapi';

async function loadMasterportalConfigTemplate (masterportalConfigJsonTemplateURL) {
	try {
		let requestedConfig;
		if (isValidUrl(masterportalConfigJsonTemplateURL)) {
			requestedConfig = await axios.get(masterportalConfigJsonTemplateURL);
		} else {
			requestedConfig = await axios.get(`${getBasePath()}/app/${masterportalConfigJsonTemplateURL}`);
		}
		return requestedConfig.data;
	} catch (error) {
		console.error('An error occured while loading the masterportal config.', error);
	}
	return null;
}

export const useConfigStore = defineStore('config', () => {
	const layers = useLayersStore();
	const graph = useGraphStore();
	const loading = useLoadingStore();
	const alert = useAlertStore();

	const admintoolConfig = reactive({});

	const layerConfUrl = ref('');
	async function loadLayerConf () {
		const { data } = await axios.get(layerConfUrl.value);
		await layers.loadLayers(data);
	}

	const styleConfUrl = ref('');
	const styleConf = reactive([]);
	async function loadStyleConf () {
		const { data } = await axios.get(styleConfUrl.value);
		styleConf.push(...data);
	}

	const restConfUrl = ref('');
	const restConf = reactive([]);
	async function loadRestConf () {
		const { data } = await axios.get(restConfUrl.value);
		restConf.push(...data);
	}

	const masterportalConfigJsonTemplate = reactive({});
	async function loadMasterportalConfigJsonTemplate () {
		const { masterportalConfigJsonTemplateURL } = admintoolConfig;
		if (masterportalConfigJsonTemplateURL) {
			Object.assign(masterportalConfigJsonTemplate, await loadMasterportalConfigTemplate(masterportalConfigJsonTemplateURL));
		}
	}

	const hostedMasterportalURL = ref('');
	const hostedMasterportalVersion = reactive({});
	async function loadHostedMasterportalVersion () {
		try {
			Object.assign(hostedMasterportalVersion, await getHostedMasterportalVersion(hostedMasterportalURL.value));
		} catch (err) { /**/ }
	}
	watch(hostedMasterportalURL, () => {
		loadHostedMasterportalVersion();
	});

	async function loadAdmintoolConfig () {
		const { data } = await axios.get(`${getBasePath()}/app/config.admintool.json`);
		Object.assign(admintoolConfig, data);
		layerConfUrl.value ||= admintoolConfig.layerConf;
		styleConfUrl.value ||= admintoolConfig.styleConf;
		restConfUrl.value ||= admintoolConfig.restConf;
		hostedMasterportalURL.value ||= admintoolConfig.hostedMasterportalURL;

		await Promise.all([
			loadLayerConf(),
			loadStyleConf(),
			loadRestConf(),
			loadMasterportalConfigJsonTemplate(),
			loadHostedMasterportalVersion(),
		]);
	}

	const masterportalTags = ref([]);
	async function loadMasterportalTags () {
		const { data: { values } } = await axios.get('https://bitbucket.org/!api/2.0/repositories/geowerkstatt-hamburg/masterportal/refs/tags?pagelen=100&q=name~%22%22&sort=-name');
		masterportalTags.value = [
			...(devModeFlag ? [ { name: 'dev' } ] : []),
			...filterMasterportalTags(values),
		];
	}

	const masterportalApiConfig = reactive({});
	async function loadMasterportalApiConfig () {
		const { data } = await axios.get(`${getBasePath()}/app/config.masterportalApi.json`);
		Object.assign(masterportalApiConfig, data);
		crs.registerProjections(data.namedProjections);
	}

	const initialized = ref(false);
	async function initialize () {
		if (initialized.value && ![
			admintoolConfig,
			styleConf,
			restConf,
			masterportalApiConfig,
		].find(it => !Object.keys(it).length)) {
			return;
		}
		loading.register();
		try {
			await Promise.all([
				loadAdmintoolConfig(),
				loadMasterportalTags(),
				loadMasterportalApiConfig(),
				useLocalConfig ? (async () => {
					await graph.fetchFromMasterportalVersion({ version: null, config: null });
				})() : Promise.resolve(),
			]);
			initialized.value = true;
		} catch (e) {
			console.error('Could not load configuration', e);
			alert.error({ content: 'errors.loadingError' });
			initialized.value = null;
		}
		loading.unregister();
	}
	function onInitialized (cb) {
		watch(initialized, value => {
			if (value === false) {
				return;
			}
			cb();
		}, { immediate: true });
	}

	/**
	 * Check if a key is in the excludedFeatures list.
	 * @returns {Function} A function that expects the key to be checked as first argument.
	 */
	const isExcludedFeature = computed(() => (key) => {
		if (!admintoolConfig.excludedFeatures) {
			return false;
		}

		return admintoolConfig.excludedFeatures
			.map(element => element.toLowerCase())
			.includes(key.toLowerCase());
		}
	);

	return {
		admintoolConfig,
		layerConfUrl,
		styleConfUrl,
		styleConf,
		restConfUrl,
		restConf,
		masterportalConfigJsonTemplate,
		hostedMasterportalURL,
		hostedMasterportalVersion,
		masterportalTags,
		masterportalApiConfig,
		initialize,
		initialized,
		onInitialized,
		isExcludedFeature,

		_persistedState: [
			'admintoolConfig',
			'layerConfUrl',
			'styleConfUrl',
			'styleConf',
			'restConfUrl',
			'restConf',
			'masterportalApiConfig',
			'hostedMasterportalVersion',
			'masterportalTags',
			'initialized',
		],
	};
});
