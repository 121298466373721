<script setup>
/* eslint-disable */
/* TODO: Remove this file when v-treeview is released into vuetify 3. This is an ugly hack. */
const props = defineProps([
	'item',
	'index',
	'first',
	'last',
]);
import { computed, toRefs } from 'vue';
const { item } = toRefs(props);
const intermediateChildren = computed(() => item.value.children?.filter(subitem => subitem.children));
const leafChildren = computed(() => item.value.children?.filter(subitem => !intermediateChildren.value.includes(subitem)));
</script>

<template lang="pug">
template(v-if="item.children")
	v-list-group(:value="item.id")
		template(#activator="{ props }")
			v-list-item(
				v-bind="props"
			)
				slot(
					name="prepend"
					:leaf="false"
					:item="item"
				)
				slot(
					name="label"
					:leaf="false"
					:item="item"
					:index="index"
					:first="first"
					:last="last"
				)
		a-treeview-item(
			v-for="(subitem, subidx) of intermediateChildren"
			:item="subitem"
			:index="subidx"
			:first="subidx === 0"
			:last="subidx === (intermediateChildren.length - 1)"
		)
			template(#label="props")
				slot(name="label" v-bind="props")
		a-treeview-item(
			v-for="(subitem, subidx) of leafChildren"
			:item="subitem"
			:index="subidx"
			:first="subidx === 0"
			:last="subidx === (leafChildren.length - 1)"
		)
			template(#label="props")
				slot(name="label" v-bind="props")
template(v-else)
	v-list-item
		slot(
			name="label"
			:leaf="true"
			:item="item"
			:index="index"
			:first="first"
			:last="last"
		)
</template>
