<script setup>
const props = defineProps({
	step: {
		type: Number,
		required: true,
	},
});

import { inject } from 'vue';

const activeStep = inject('AStepper-activeStep');
const steps = inject('AStepper-steps');
</script>

<template lang="pug">
v-list-item.content(v-if="props.step !== steps" ripple)
	template(v-if="props.step === activeStep")
		slot
</template>

<style lang="scss" scoped>
.content {
	border-left: 1px solid rgba(0, 0, 0, 0.12);
	margin: -8px -36px -16px 36px;
	padding: 16px 60px 16px 23px;
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
</style>
