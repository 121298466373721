import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useAlertStore = defineStore('alert', () => {
	const value = ref(false);
	const type = ref('success');
	const content = ref('');
	const options = ref({});
	let timeoutId;

	const alertTypes = [
		'success',
		'info',
		'warning',
		'error',
	];

	function register ({ alertType, alertContent, alertOptions, timeout = 5000 }) {
		if (typeof timeoutId === 'number') {
			clearTimeout(timeoutId);
		}
		type.value = alertType;
		content.value = alertContent;
		options.value = alertOptions;
		value.value = true;
		timeoutId = setTimeout(() => {
			value.value = false;
		}, timeout);
	}

	return {
		value,
		type,
		content,
		options,
		...Object.fromEntries(alertTypes.map(alertType => ([
			alertType,
			({ content: alertContent, options: alertOptions, timeout }) => register({ alertType, alertContent, alertOptions, timeout }),
		]))),
	};
});
