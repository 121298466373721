<script setup>
/** Body to contain navigation and route form/contents. */
import { usePageLayoutStyleStore } from '@/store/pageLayoutStyle';

const pageLayoutStyle = usePageLayoutStyleStore();
</script>

<template lang="pug">
.grow(v-show="!pageLayoutStyle.isBodyHidden")
	.content-body
		slot
</template>

<style lang="scss" scoped>
@import '../globals.scss';

.grow {
	flex-grow: 1;
	flex-shrink: 0;
}

.content-body {
	position: relative;
	display: flex;
	flex-direction: column;

	background: $hamburg-background;
	padding: 1em;

	/* http://gs.statcounter.com/screen-resolution-stats/desktop/germany using value below 1366 since that's still quite common */
	max-width: 1200px;
	margin: 0 auto 1.5em auto;
}

@media (min-width: 600px) {
	.content-body {
		flex-direction: row;
	}
}
</style>
