import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { defineStore } from 'pinia';
import { useExpertModeStore } from '@/store/expertMode';
import { useGraphStore } from '@/store/graph';
import { useConfigStore } from '@/store/config';
import resolvePath from '@/lib/resolvePath';
import { getReferencedNodeKeys } from '@masterportal/mpconfigparser';

const introductionChapter = [
	{
		chapterKey: 'introduction',
		label: 'chapters.introduction.sideBarTitle',
		route: { name: 'Introduction' },
		icon: 'done',
	},
];

const loadConfigChapter = [
	{
		chapterKey: 'loadconfig',
		label: 'chapters.loading.sideBarTitle',
		route: { name: 'LoadConfig' },
		icon: 'done',
	},
];

const exportChapter = [
	{
		chapterKey: 'export',
		label: 'chapters.export.sideBarTitle',
		route: { name: 'PreviewAndPublish' },
		icon: 'done',
	},
];

export const useChaptersStore = defineStore('chapters', () => {
	const expertMode = useExpertModeStore();

	// recursively gets keys in correct order of appearance in document
	function lookForKeys (node, keys, graph) {
		if (node && node.h <= 4) {
			// should this break, set only the following element as a quick-fix
			const nextKeys = getReferencedNodeKeys(node, true, expertMode.expertMode);
			nextKeys.forEach(key => {
				keys.push(key);
				lookForKeys(graph.graph.nodes[key.key], keys, graph);
			});
		}
	}

	/**
	 * Uses the lookForKeys-function to get the chapter-keys recursively.
	 * @param { object } node the current node.
	 * @param { useGraphStore } graph the graph store.
	 * @returns { array } keys represent all keys for the current node incl. the keys for the subchapters.
	 */
	function getKeys (node, graph) {
		const theKeys = [];
		lookForKeys(node, theKeys, graph);
		return theKeys;
	}

	/**
	 * Builds a Chapter-Entry.
	 * @param { object } node the current node.
	 * @param { array } keys array of all chapter-keys and subchapter-keys.
	 * @param { useGraphStore } graph the graph store.
	 * @param { array } excludedChapters List of chapters to exclude.
	 * @returns { Chapter[] } array with chapter-entry to be added to the chapters.
	 */
	function buildChapterEntry (node, keys, graph, excludedChapters) {
		return [
			{
				...node,
				route: { name: 'EditConfig', params: { path: node.chapterKey } }, // generated editor for all other h2
				icon: 'edit',
				rules: [], // TODO see validation branch
			},
			keys
				// only create subchapters for h3
				.filter(o => graph.graph.nodes[o.key].h === 3)
				// only create subchapters if a matching object currently exists
				.filter(o => graph.configTarget([ ...graph.graph.nodes[o.key].path, o.name ]))
				// only create subchapters for not excluded chapters
				.filter(o => !excludedChapters
					.find(ec => ec.toLowerCase() === graph.graph.nodes[o.key].chapterKey.toLowerCase()))
				.map(o => {
					const childNode = graph.graph.nodes[o.key];
					return {
						...childNode,
						route: {
							name: 'EditConfig',
							params: {
								path: childNode.chapterKey,
							},
						},
						valid: true, // TODO see validation branch
					};
				}),
		];
	}

	/**
	 * Builds a chapter-entry for the layerSelection.
	 * @param { object } node containing the node.
	 * @param { array } excludedChapters List of chapters to exclude.
	 * @returns { Chapter[] } chapter array with chapter-entry to be added to the chapters.
	 */
	function buildLayerSelection (node, excludedChapters) {
		const subChapters = [
			{
				chapterKey: 'GroupConfig',
				label: 'Groupconfig',
				h: 3,
				route: { name: 'GroupConfig' },
				rules: [],
			},
			{
				chapterKey: 'LayerConfig',
				label: 'Layerconfig',
				h: 3,
				route: { name: 'LayerOverride' },
				rules: [],
			},
			{
				chapterKey: 'StyleConfig',
				label: 'Styleconfig',
				h: 3,
				route: { name: 'StyleConfig' }, // separate solution for layer selection
				rules: [],
			},
			{
				chapterKey: 'GfiConfig',
				label: 'Gficonfig',
				h: 3,
				route: { name: 'GfiConfig' },
				rules: [],
			},
		].filter(c => !excludedChapters
			.find(ec => ec.toLowerCase() === `themenconfig.${c.chapterKey.toLowerCase()}`));

		return [
			{
				...node,
				route: { name: 'SelectLayers' }, // separate solution for layer selection
				icon: 'edit',
				rules: [], // TODO see validation branch
			},
			subChapters,
		];
	}

	/**
	 * Decides which function is to be used to build the chapter.
	 * @param { string } chapterKey the key for the current node.
	 * @param { array } keys array of all chapter-keys and subchapter-keys.
	 * @param { boolean } isLayerSelection decides wether it es a layerSelection or not.
	 * @param { useGraphStore } graph the graph store.
	 * @param { array } excludedChapters List of chapters to exclude.
	 * @returns { Chapter[] } chapter array with chapter-entry to be added to the chapters.
	 */
	function buildChapter (chapterKey, keys, isLayerSelection, graph, excludedChapters) {
		const node = graph.graph.nodes[chapterKey];
		if (isLayerSelection) {
			return buildLayerSelection(node, excludedChapters);
		}
		return buildChapterEntry(node, keys, graph, excludedChapters);
	}

	/**
	 * Combines information to work for navigation renderer.
	 * @returns {[(Chapter|ChapterBase), SubChapter[]][]} array of chapter/subChapter[] pairs
	 */
	const chapters = computed(() => {
		const graph = useGraphStore();
		const config = useConfigStore();

		const excludedChapters = config.admintoolConfig.excludedChapters ?? [];
		const startChapters = [];
		const endChapters = [];
		if (!excludedChapters.find(excludedChapter => excludedChapter.toLowerCase() === 'introduction')) {
			const introductionSubChapters = [ 'layerConf', 'restConf', 'styleConf' ]
				.filter(key => !excludedChapters.find(ec => ec.toLowerCase() === key.toLowerCase()))
				.map(key => ({
					chapterKey: key,
					label: `chapters.loading.jsons.${key}.sideBarTitle`,
					h: 3,
					route: { name: 'LoadJSONFile', params: { path: key } },
				}));
			startChapters.push([ ...introductionChapter, introductionSubChapters ]);
		}
		if (!excludedChapters.find(excludedChapter => excludedChapter.toLowerCase() === 'loadconfig')) {
			startChapters.push(loadConfigChapter);
		}
		if (!excludedChapters.find(excludedChapter => excludedChapter.toLowerCase() === 'export')) {
			endChapters.push(exportChapter);
		}

		if (!graph.graph || !Object.keys(graph.graph).length) {
			return startChapters;
		}

		return [
			...startChapters,
			...graph.graph.chapterOrder
				.filter(chapterKey => !excludedChapters.find(excludedChapter => excludedChapter.toLowerCase() === chapterKey.toLowerCase()))
				.map(chapterKey => {
					const node = graph.graph.nodes[chapterKey];
					const isLayerSelection = chapterKey === 'Themenconfig';
					const keys = getKeys(node, graph);

					// do not create entries for inexistant objects (object may be deactivated from superior level)
					if (!resolvePath(graph.graph.nodes, [ ...node.path, node.name ])) {
						return null;
					}

					return buildChapter(chapterKey, keys, isLayerSelection, graph, excludedChapters);
				})
				.filter(it => it !== null),
			...endChapters,
		];
	});
	const route = computed(() => useRoute());
	const activeStep = computed(() => {
		const currentRouteName = route.value?.name;
		const idx = chapters.value.findIndex(([ rootItem, childItems ]) => rootItem.route.name === currentRouteName || childItems?.some(childItem => childItem.route.name === currentRouteName));
		return idx > 0 ? idx + 1 : 1;
	});

	function findIndexOfActiveSubChapter (chapter, currentRoute) {
		const [ , subchapters ] = chapter;
		if (subchapters && subchapters.length) {
			const routeMatcher = { name: currentRoute.name };
			if (currentRoute.params && Object.keys(currentRoute.params).length > 0) {
				routeMatcher.params = currentRoute.params;
			}
			return subchapters.findIndex(({ route: chapterRoute }) => JSON.stringify(chapterRoute) === JSON.stringify(routeMatcher));
		}
		return -1;
	}

	function getNextStep (currentRoute, forwards) {
		const activeChapterIndex = activeStep.value - 1;
		const activeChapter = chapters.value[activeChapterIndex];
		const hasSubChapters = activeChapter[1] && activeChapter[1].length;
		const chosenCurrentSubChapterIndex = findIndexOfActiveSubChapter(activeChapter, currentRoute);
		const nextChapter = chapters.value[activeChapterIndex + (forwards ? 1 : -1)];
		const nextHasSubChapters = nextChapter[1] && nextChapter[1].length;

		if (forwards) {
			if (!hasSubChapters || chosenCurrentSubChapterIndex + 1 >= activeChapter[1].length) {
				// go to next chapter from current chapter OR last subchapter of current chapter
				return chapters.value[activeChapterIndex + 1][0];
			}
			// go to next subchapter of current chapter OR go from -1 to first subchapter
			return activeChapter[1][chosenCurrentSubChapterIndex + 1];
		} else if (hasSubChapters && chosenCurrentSubChapterIndex !== -1) {
			// if we're within a chapter's subchapters ...
			const prevSubchapter = chosenCurrentSubChapterIndex - 1;
			if (prevSubchapter >= 0) {
				// go to prev subchapter of current chapter
				return activeChapter[1][prevSubchapter];
			}
			// go to main chapter from first subchapter
			return chapters.value[activeChapterIndex][0];
		} else /* if we're in a main chapter */ if (nextHasSubChapters) {
			// go to last subchapter of prev chapter from current subchapter
			return chapters.value[activeChapterIndex - 1][1][chapters.value[activeChapterIndex - 1][1].length - 1];
		}
		// if prev chapter has no subchapters, just go to prev chapter
		return chapters.value[activeChapterIndex - 1][0];
	}

	return {
		activeStep,
		getNextStep,
		chapters,
	};
});
