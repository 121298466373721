export default {
	controls: {
		stepper: {
			previous: 'Previous step',
			next: 'Next step',
		},
		changeLanguage: 'Language',
		expertMode: {
			label: 'Expert mode',
			title: 'Shows fields for advanced users.',
		},
		coordinate: {
			label: {
				longLatX: 'Longitude [°]',
				longLatY: 'Latitude [°]',
				unitX: 'Easting [{{unit}}]',
				unitY: 'Northing [{{unit}}]',
				otherX: 'Easting',
				otherY: 'Northing',
			},
		},
		map: {
			closePreview: 'Close preview',
		},
		collapse: {
			tooltip: 'Collapse / expand',
		},
	},
	forms: {
		formElement: {
			entryType: 'Enter as',
			text: 'text',
			checkbox: 'checkbox',
			number: 'number',
			naturalNumber: 'integer',
			coordinate: 'coordinate',
			extent: 'extent',
			list: 'list',
			enum: 'multiple choice',
		},
		inputArray: {
			addEntry: 'Add element',
			defaultValue: 'Default value: {{value}}',
			xElements: 'element(s).',
		},
		inputCoordinate: {
			emptyField: 'Please enter a value',
			invalid:
				'Invalid value: Allowed character are: 0-9 and dot or comma as decimal separator.',
			outsideExtent:
				'The entered coordinate is not within the defined extent.',
		},
		inputExtent: {
			emptyField: 'Please enter a value',
			invalid:
				'Invalid value: Allowed characters are: 0-9 and dot or comma as decimal separator.',
			north: 'North',
			east: 'East',
			south: 'South',
			west: 'West',
		},
		// also used for inputNumberMulti
		inputNumber: {
			invalidInteger: 'This field only allows entering integers.',
			invalidFloat:
				'Only numbers are allowed in this field. Please separate decimal points with a dot, e.g. "1.5".',
		},
		inputString: {
			emptyField: 'This field is required.',
		},
		inputObject: {
			emptyProperty: 'At least one required input is not filled yet.',
		},
		inputCommons: {
			startTyping: 'Start typing to filter the suggestions.',
		},
		inputLayer: {
			availableLayers: 'Available Layers',
			startTyping: 'Start typing to filter the suggestions.',
			description:
				'Selection of the layer ID using the available layers from services-internet.json',
		},
		inputEPSG: {
			availableEPSG: 'Available EPSG',
			startTyping: 'Start typing to filter the suggestions.',
			description: 'Selection of the EPSG',
		},
		inputRestService: {
			startTyping: 'Start typing to filter the suggestions.',
			description: 'Selection of the rest services',
		},
	},
	storage: {
		title: 'Hint',
		paragraphs: {
			one:
				'The state previously created in this browser has been loaded. It was created <b>{{displayDate}}</b> at <b>{{displayTime}}</b> and contains a configuration for Masterportal version <b>{{version}}</b>.',
			two:
				'To use a different configuration file, or edit this file in a different version, please use the chapter <i></i>"Choose initial configuration"</i>.',
		},
		action: 'OK',
	},
	errors: {
		loadingError:
			'Unable to load admintool configuration. Please check your network connection.',
		nameDuplicate:
			'The chosen name is already in use. Please use a different name.',
		generalSavingError: 'An error occurred on saving the configuration.',
		notFound: {
			title: 'Error #404',
			text:
				'The chosen contents could not be found. Please use the navigation to return to an existing page.',
			action: 'Back to the start page',
		},
		invalidDocumentation:
			"This version's documentation could not be processed.",
		fetchingError:
			'Fetching data failed. Please check the entered URL and your network connection.',
	},
	chapters: {
		introduction: {
			title: 'Introduction and choose global configuration',
			sideBarTitle: 'Choose global configuration',
			paragraphs: {
				one: 'Welcome to Masterportal Admin.',
				two:
					'This tool offers the creation and modification of configuration files for your Masterportal instance. You may also publish configurations to immediately create Masterportal instances available online.',
				three: 'You may change the global configurations (map services, REST services and style configuration) first. After that, you may choose an initial configuration and further edit that configuration.',
			},
			warning:
				'This tool is in its beta phase and may contain errors. To help us improve your experience, please send any feedback to ',
			furtherReading: 'Further reading on the Masterportal',
			cleanHint: 'You are able to delete and reload local data if needed:',
			clean: 'Remove local data',
			dialogConfirm: 'Remove',
			cleanWaring: 'Are you sure? By removing the local data, all entered changes to the current configuration are lost!',
		},
		loading: {
			title: 'Choose initial configuration',
			sideBarTitle: 'Choose initial configuration',
			localConfig: 'Loaded local configuration!',
			paragraph:
				"Load an existing configuration from your computer, or start with the default configuration of any supported Masterportal version. Please mind that loading any file in any way will reset the application's current state, and any unsaved changes will be lost.",
			hintTitle: 'Data loss',
			hint: 'By loading the new configuration, all entered changes to the current configuration are lost.',
			dialogAbort: 'Abort',
			dialogConfirm: 'Load',
			fromTemplate: {
				title: 'Use a template to create a configuration',
				actionEdit: 'Use template',
				description:
					'A template provided by the organisation hosting this instance of Masterportal Admin can be used as origin for a configuration.',
				printVersion:
					'This template was created to work along with Masterportal {{version}}.',
				unsupportedVersion:
					"The given template either doesn't contain information on which version of Masterportal it was created for or the version isn't supported. Therefore it's not possible to use this template.",
				invalid:
					'The loaded template does not seem to be a valid Masterportal configuration file.',
			},
			fromUrl: {
				urlFieldLabel: 'internet address',
				loadUrlConfig: 'load configuration',
				addUrl: 'Please provide the internet address of the configuration to work on',
				radio: 'From the internet',
				configInvalid: 'The loaded configuration is invalid.',
				urlInvalid: 'The provided internet address is invalid',
				loadingError: 'Due to an network error, the given configuration file could not be loaded. Please check your network connection and check if the configuration is reachable. If the file is reachable when accessed directly, a CORS error may cause this if applicable. Contact the administrator of the server hosting the configuration or download it manually and re-upload it here.',
				urlPlaceholder: 'https://path/to/config.json',
			},
			fromComputer: {
				title: 'Upload from computer or internet',
				radio: 'Upload',
				versionLabel: 'Masterportal version',
				versionPlaceholderManual:
					'Please choose your Masterportal version',
				versionPlaceholderAutomatic: 'Automatically inferred from file',
				actionUpload: 'Choose configuration file',
				actionUploadExplanation:
					'Please choose the configuration file to edit from your hard drive',
				actionEdit: 'Edit configuration',
				upload: {
					identified:
						'The loaded file belongs to version {{fileVersion}}. It will be processed with the matching Masterportal version.',
					identifiedMigrate:
						'The loaded file belongs to version {{fileVersion}}. You have chosen to migrate to Masterportal version {{version}}.',
					unsupported:
						"The file's version {{fileVersion}} is not supported. Please choose another version above that shall be used to edit this file instead.",
					unsupportedMigrate:
						"The file's version {{fileVersion}} is not supported. You have chosen to migrate it to Masterportal version {{version}}.",
					unversioned:
						'The file does not contain version information. Please choose a Masterportal version to proceed. On saving this file, the chosen version will be noted in it.',
					invalid:
						'The loaded file does not seem to be a Masterportal configuration file. Please verify your choice.',
					broken:
						'The loaded file does not contain valid JSON. Please verify your file does not hold any syntax errors.',
					nothing:
						'Please choose the file to edit from your hard drive.',
				},
				errorUpload:
					'The files required to work on Masterportal version {{version}} could not be loaded.',
				errorNavigation:
					'Navigation failed. Please use the sidebar to choose an appropriate next step.',
				errorGroupLayer:
					'The contained layer "{{name}}" is a group layer. Masterportal Admin does currently not support group layers.',
				errorNotFound:
					'Layer "{{name}}" (ID: {{id}}) could not be found.',
				successLoad:
					'The chosen configuration has been loaded to Masterportal Admin.',
				invalidLayers:
					'The chosen configuration has been loaded, but some layers could not be found. Please check if the right map service configuration was loaded.',
			},
			jsons: {
				errorUpload: 'An error occurred while loading the file: {{ error }}',
				errorFormat: 'The data format is invalid.',
				missingProp: 'The property "{{ property }}" is missing',
				hintTitle: 'Missing preview',
				hint: 'Hint: If you load a configuration from the internet, the options "Preview" and "Publish" will not be available at the menu item "Export"!',
				dialogAbort: 'Abort',
				dialogConfirm: 'Confirm',
				loadedFile: 'Current loaded file',
				description: 'Loaded elements',
				layerConf: {
					title: 'Change map service configuration',
					sideBarTitle: 'Map services',
					typeError: 'Unsupported layer type was found.',
					paragraph:
						'Load an alternative map service configuration via url.',
				},
				restConf: {
					title: 'Change the REST service configuration',
					sideBarTitle: 'REST services',
					paragraph:
						'Load an alternative REST service configuration via url.',
				},
				styleConf: {
					title: 'Change the style configuration',
					sideBarTitle: 'Styles',
					paragraph:
						'Load an alternative style configuration via url.',
				},
				fromURL: {
					title: 'Load configuration from URL',
				},
				upload: {
					title: 'Upload local configuration file',
				},
			},
			masterportalDefault: {
				title: "Load a Masterportal version's default",
				paragraph:
					'Please choose a Masterportal version to create a configuration for. Only supported Masterportal versions will be listed.',
				versionLabel: 'Masterportal version',
				action: 'Use selected configuration',
				actionsmall: 'Select',
			},
		},
		portalconfig: {},
		themenconfig: {
			title: 'Choose layer',
			sideBarTitle: 'Choose layer',
			paragraphs: {
				one:
					'This list allows choosing layers to be available in your portal.',
				two:
					'To the left, all offered layers are presented. For a quick search, you may filter the list by layer name.',
				three: {
					partOne:
						'Move layers to the right side via drag and drop to make them available in your Masterportal. A click to the map symbol (',
					partTwo:
						') will preview the layer. Furthermore, you may use the eye symbol (',
					partThree:
						') to define whether a layer should be visible in your portal initially.',
				},
				four: 'By double-clicking a layer, its name may be edited. The name can be confirmed by pressing Enter.',
				custom: {
					partOne: 'A click to the plus symbol (',
					partTwo: ') will create a new folder. It can be deleted again using the trash symbol (',
					partThree: '). The symbol ',
					partFour: ' allows to configure if the folder is selectable.',
				},
			},
			treeType: 'Tree type',
			treeTypes: {
				light: 'Flat',
				custom: 'Nested',
			},
			filterTitle: 'Search layers',
			availableLayers: 'Available layers',
			chosenLayers: 'Chosen layers',
			chosenLayersAndFolders: 'Chosen layers and folder structure',
			dragLayers:
				'Available layers can be dragged to a position of the Masterportal table on the right side. The order of layers is dependend on the position of the layer in the list, where a higher positioned layer gets rendered on top of the others.',
			preview: 'Preview',
			visibility: 'Visibility',
			moveLayer: {
				up: 'move up',
				down: 'move down',
			},
			overrideLayer: {
				title: 'Configure layers',
				paragraph: 'This page allows creating and configuring additional layers.',
				wms: {
					title: 'WMS layer',
					name: 'WMS service name',
					url: 'WMS service url',
					version: 'WMS version',
					mpadminFeatures: 'Selected feature',
					layers: 'Feature ID',
					layerAttribution: 'Layer attribution',
					minScale: 'Minimum scale',
					maxScale: 'Maximum scale',
					legendURL: 'Legend (URL)',
				},
				createAction: 'Create',
				configAction: 'Configure',
				deleteAction: 'Delete',
			},
			groupLayer: {
				title: 'Configure group layers',
				paragraph: 'A group layer is a layer, that groups layers in a way they can be (un-)selected only all or none. The layers have to be included into Themenconfig as usual.',
				choose: 'Choose layer',
				name: 'Group layer name',
				createAction: 'Create additional group layer',
				deleteAction: 'Delete group layer',
			},
			styleLayer: {
				title: 'Style layers',
				paragraph: 'This list allows styling WFS layers.',
				selected: 'Selected WFS layers',
				name: 'Name',
				preview: 'Preview',
				styleId: 'Style ID',
				experimentalType: 'The styling for this layer type is not fully supported by Masterportal!',
			},
			gfiLayer: {
				title: 'Select GFI attributes',
				paragraph: 'This list allows selecting GFI attributes. To rename or (de)select single attributes, the option "Configure attribute set" has to be chosen.',
				customParagraph: 'The visibility of an attribute is displayed with an icon in the corresponding line. An attribute can be enabled by setting a title and disabled by removing the title.',
				selected: 'Selected layers',
				attribute: 'Attribute',
				name: 'Title',
				native: 'Use default layer configuration',
				showAll: 'Show all attributes',
				custom: 'Configure attribute set',
			},
		},
		export: {
			title: 'Exporting the created configuration',
			incompatibleVersion:
				'The selected version ({{ targetVersion }}) differs from the version of the connected Masterportal ({{ hostedMasterportalVersion }}). Applying the created configuration might not lead to the expected result.',
			sideBarTitle: 'Export',
			paragraph:
				'Please use one of the following options to export your Masterportal configuration. The options Preview and Publish are especially useful for a quick overview on whether the configuration meets your expectations, since no Masterportal instance has to be installed to gain insights. When choosing a download option, the Masterportal has to be installed on your own on a server/webspace. However, the download options offer more flexibility for further adjustments.',
			instanceURL: 'URL of a fitting Masterportal instance',
			preview: {
				title: 'Preview',
				paragraph:
					'The preview function can be used to check how the Masterportal will look like with your current configuration. To enable this, your configuration file will be uploaded to the server. The created link will work for at least 24 hours. It may be deleted at any time later.',
				previewUrl:
					'The following URL offers a preview of your current configuration:',
				action: 'Create preview',
			},
			publish: {
				title: 'Publish',
				paragraph:
					'The publish function allows permanently hosting a configuration. For this, your configuration file will be uploaded to a path you may define in the following. This upload is permanent.',
				configUrl: {
					partOne:
						'The configuration has been saved. The creates JSON file can be viewn at ',
					partTwo:
						'. Alternatively, you may directly view the portal at: ',
				},
				placeholder: 'MyPortal',
				action: 'Publish configuration',
				overwrite: 'Overwrite existing configuration',
			},
			downloadPortal: {
				title: 'Download a configured Masterportal instance',
				paragraph:
					'Download a configured Masterportal instance, which may be published by copying it to a Webspace.',
				action: 'Download',
			},
			downloadConfig: {
				title: 'Download the central configuration file',
				paragraph:
					'The manually edited configuration file may be downloaded at any time to either edit it manually or configure a separately running Masterportal. A downloaded file may be re-uploaded later to edit it further.',
				action: 'Download',
			},
			text: {
				title: 'Display the central configuration file',
				paragraph: 'Display the central configuration file inline',
			},
			quickPreview: {
				title: 'Quick preview',
			},
			errors: {
				missingName: 'Please enter a name for your configuration.',
				invalidName:
					'Invalid input: Allowed character are: Aa-Zz, 0-9, -, and _.',
				invalidScheme:
					'The name must match the following scheme: "MyDistrict_MyPortal".',
				buildError:
					'An error occurred while building the Masterportal instance.',
				publishError: 'An error happened during publishing.',
			},
		},
	},
};
