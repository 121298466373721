<script setup>
const props = defineProps({
	isBackEnabled: {
		type: Boolean,
		default: true,
	},
	isNextEnabled: {
		type: Boolean,
		default: true,
	},
});

import { toRefs } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useChaptersStore } from '@/store/chapters';

const { isBackEnabled, isNextEnabled } = toRefs(props);
const chapters = useChaptersStore();
const route = useRoute();
const router = useRouter();

function navigate (target) {
	router.push(target?.route || target);
	window.scrollTo(0, 0);
}

function navigateStep (forwards) {
	const nextStep = chapters.getNextStep(route, forwards);
	navigate(nextStep);
}
</script>

<template lang="pug">
div.d-flex.justify-space-between.card-wrap
	v-btn(
		v-if="chapters.activeStep > 1"
		:disabled="!isBackEnabled"
		@click="navigateStep(false)"
		flat
	)
		v-icon navigate_before
		| {{ $t("controls.stepper.previous") }}
	//- placeholder div so that buttons are still pushed left/right when only one is present
	div
	v-btn(
		v-if="chapters.activeStep < chapters.chapters.length"
		:disabled="!isNextEnabled"
		@click="navigateStep(true)"
		flat
	)
		| {{ $t('controls.stepper.next') }}
		v-icon navigate_next
</template>

<style lang="scss" scoped>
@import '../globals.scss';

.card-wrap {
	flex-wrap: wrap-reverse;
}
</style>
