import axios from 'axios';

/**
 * Create string representation of version object
 *
 * @param {object} version Version object
 * @returns {string} String representation
 */
export function versionToString (version) {
	if (!version || version === 'dev') return version;
	return `${version.major}.${version.minor}.${version.patch}`;
}

/* eslint-disable-next-line consistent-return */
export function getMasterportalVersion (versionString) {
	const match = versionString.match(/^v?(?<major>[0-9]+)\.(?<minor>[0-9]+)\.(?<patch>[0-9]+)$/);
	if (match?.groups) {
		return Object.fromEntries(Object.entries(match.groups).map(([ key, value ]) => [ key, Number(value) ]));
	}
}

/**
 * Get version of hosted Masterportal
 *
 * This function detects the version of the hosted Masterportal which is used for
 * preview / publish of configurations. Knowing the version is necessary as one wants to
 * know whether the hosted Masterportal supports the created configuration.
 *
 * @param {string} hostedMasterportalURL Url of the hosted Masterportal
 * @returns {object} Version of the hosted Masterportal or undefined if version couldn't be detected
 */
/* eslint-disable-next-line consistent-return */
export async function getHostedMasterportalVersion (hostedMasterportalURL) {
	const response = await axios.get(hostedMasterportalURL);
	// The index.html refers to the Masterportal javascript file. In case the masterportal was bundled with the `buildExample`
	// command (which is assumed for production sites) this file is stored within the folder `mastercode/<version>/js/`. The
	// following regex searches for this pattern and extracts the version, which is assumed to contain a major, minor and patch
	// number (according to SemVer) which are separated by a underscore. In case the portal was build using the `build` or
	// `buildPortal` command it's not possible to derive the version number from the `index.html`.
	const match = response.data.match(/mastercode\/(?<major>[0-9]+)_(?<minor>[0-9]+)_(?<patch>[0-9]+)\/js\/masterportal\.js/);
	if (match?.groups) {
		return Object.fromEntries(Object.entries(match.groups).map(([ key, value ]) => [ key, Number(value) ]));
	}
}
