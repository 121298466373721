export default {
	controls: {
		stepper: {
			previous: 'Vorheriger Schritt',
			next: 'Nächster Schritt',
		},
		changeLanguage: 'Sprache',
		expertMode: {
			label: 'Expertenmodus',
			title: 'Zeigt Felder für fortgeschrittene Nutzer an.',
		},
		coordinate: {
			label: {
				longLatX: 'Länge [°]',
				longLatY: 'Breite [°]',
				unitX: 'Rechtswert [{{unit}}]',
				unitY: 'Hochwert [{{unit}}]',
				otherX: 'Rechtswert',
				otherY: 'Hochwert',
			},
		},
		map: {
			closePreview: 'Vorschau schließen',
		},
		collapse: {
			tooltip: 'Ein- / ausklappen',
		},
	},
	forms: {
		formElement: {
			entryType: 'Eingabe als',
			text: 'Text',
			checkbox: 'Checkbox',
			number: 'Zahl',
			naturalNumber: 'Ganzzahl',
			coordinate: 'Koordinate',
			extent: 'Ausdehnung',
			list: 'Liste',
			enum: 'Auswahl aus Vorgaben',
		},
		inputArray: {
			addEntry: 'Element hinzufügen',
			defaultValue: 'Defaultwert: {{value}}',
			xElements: 'Element(e).',
		},
		inputCoordinate: {
			emptyField: 'Bitte einen Wert eingeben',
			invalid:
				'Ungültige Eingabe: Erlaubte Zeichen sind: 0-9 sowie ein Punkt oder Komma als Dezimaltrennzeichen.',
			outsideExtent:
				'Die eingegebene Koordinate liegt nicht im definierten Extent.',
		},
		inputExtent: {
			emptyField: 'Bitte einen Wert eingeben',
			invalid:
				'Ungültige Eingabe: Erlaubte Zeichen sind: 0-9 sowie ein Punkt oder Komma als Dezimaltrennzeichen.',
			north: 'Nord',
			east: 'Osten',
			south: 'Süden',
			west: 'Westen',
		},
		// also used for inputNumberMulti
		inputNumber: {
			invalidInteger: 'In diesem Feld sind nur Ganzzahlen erlaubt.',
			invalidFloat:
				'In diesem Feld sind nur Zahlen erlaubt. Nachkommastellen bitte mit Punkt trennen, bspw. "1.5".',
		},
		inputString: {
			emptyField: 'Dieses Feld muss befüllt werden.',
		},
		inputObject: {
			emptyProperty: 'Mindestens ein Pflichtfeld ist noch nicht ausgefüllt.',
		},
		inputCommons: {
			startTyping: 'Start typing to filter the suggestions.',
		},
		inputLayer: {
			availableLayers: 'Verfügbare Layer',
			startTyping: 'Beginnen Sie zu Tippen um die Vorschläge zu filtern.',
			description:
				'Auswahl der Layer Id mit Hilfe der verfügbaren Layer aus der services-internet.json',
		},
		inputEPSG: {
			availableEPSG: 'Verfügbare EPSG',
			startTyping: 'Beginnen Sie zu Tippen um die Vorschläge zu filtern.',
			description: 'Auswahl des EPSG',
		},
		inputRestService: {
			startTyping: 'Beginnen Sie zu Tippen um die Vorschläge zu filtern.',
			description: 'Auswahl des Service',
		},
	},
	storage: {
		title: 'Hinweis',
		paragraphs: {
			one:
				'Der zuvor in diesem Browser verwendete Stand wurde geladen. Dieser wurde am <b>{{displayDate}}</b> um <b>{{displayTime}} Uhr</b> gespeichert und beinhält eine Konfigurationsdatei für die Masterportalversion <b>{{version}}</b>.',
			two:
				'Um eine andere Konfigurationsdatei zu bearbeiten, oder die Datei in einer anderen Version zu bearbeiten, benutzen Sie bitte <i>"Ausgangskonfiguration wählen"</i>.',
		},
		action: 'OK',
	},
	errors: {
		loadingError:
			'Die Konfiguration des Admintools konnte nicht vollständig geladen werden. Bitte überprüfen Sie ihre Netzwerkverbindung.',
		nameDuplicate:
			'Der für die Konfiguration gewählte Name ist bereits vergeben. Bitte wählen Sie eine andere Bezeichnung.',
		generalSavingError:
			'Beim Speichern der Konfiguration ist ein Fehler aufgetreten.',
		notFound: {
			title: 'Fehler #404',
			text:
				'Der ausgewählte Inhalt konnte leider nicht gefunden werden. Benutzen Sie die Navigation, um zurück zu einer existierenden Seite zu kommen.',
			action: 'Zurück zur Startseite',
		},
		invalidDocumentation:
			'Die Dokumentation für diese Version konnte nicht verarbeitet werden.',
		fetchingError:
			'Die Daten konnten nicht abgerufen werden. Bitte überprüfen Sie die eingegebene URL und ihre Netzwerkverbindung.',
	},
	chapters: {
		introduction: {
			title: 'Einführung und globale Konfiguration',
			sideBarTitle: 'Globale Konfigurationen wählen',
			paragraphs: {
				one: 'Willkommen bei Masterportal Admin.',
				two:
					'Mit diesem Tool können Sie Konfigurationen für Ihre Masterportalinstanz bearbeiten und erstellen. Ebenso können solche Konfigurationen auch veröffentlicht werden, sodass die Ergebnisse sofort im Internet verfügbar sind.',
				three: 'Unter dem Punkt "Globale Konfiguration wählen" können die Kartendienste-, REST-Dienste- und Style-Konfiguration angepasst werden. Anschließend kann eine Ausgangskonfiguration gewählt und diese weiter bearbeitet werden.',
			},
			warning:
				'Dieses Tool ist derzeit noch in der Beta-Phase und kann Fehler enthalten. Helfen Sie mit, es besser zu machen und geben Sie uns gerne Feedback unter ',
			furtherReading: 'Mehr Informationen zum Masterportal',
			cleanHint: 'Falls erforderlich, erzwingen Sie hiermit das Löschen und Neuladen der lokalen Daten:',
			clean: 'Lokale Daten entfernen',
			dialogConfirm: 'Entfernen',
			cleanWaring: 'Sind Sie sicher? Durch das Löschen der lokalen Daten gehen alle Änderungen im Adminbereich verloren.',
		},
		loading: {
			title: 'Ausgangskonfiguration wählen',
			sideBarTitle: 'Ausgangskonfiguration wählen',
			localConfig: 'Lokale Konfiguration geladen!',
			paragraph:
				'Laden Sie eine bestehende Konfiguration von Ihrem Rechner, oder starten Sie von der Defaultkonfiguration einer Version des Masterportals aus. Wenn Sie das Laden einer Datei bestätigen, werden ungespeicherte Änderungen im restlichen Adminbereich verworfen.',
			hintTitle: 'Verlust der eingegebenen Daten',
			hint: 'Durch das Laden der neuen Konfiguration gehen alle Änderungen im Adminbereich verloren.',
			dialogAbort: 'Abbrechen',
			dialogConfirm: 'Laden',
			fromTemplate: {
				title: 'Eine Vorlage als Ausgangspunkt verwenden',
				actionEdit: 'Vorlage verwenden',
				description:
					'Von der Organisation, welche diese Instanz von Masterportal Admin betreibt, wird eine Vorlage bereitgestellt. Diese Vorlage kann als Grundlage für die weitere Konfiguration verwendet werden.',
				printVersion:
					'Die Vorlage ist zur Verwendung mit Masterportal {{version}} ausgelegt.',
				unsupportedVersion:
					'Die Vorlage beinhaltet entweder keine Informationen darüber, mit welcher Version des Masterportals sie zu verwenden ist, oder die angegeben Version wird nicht unterstützt. Eine Verwendung der Vorlage ist deshalb nicht möglich.',
				invalid:
					'Die verknüfte Vorlage scheint keine gültige Masterportal-Konfigurationsdatei zu sein.',
			},
			fromUrl: {
				urlFieldLabel: 'Internetadresse',
				loadUrlConfig: 'Konfiguration laden',
				addUrl: 'Bitte geben Sie die Internetadresse der zu bearbeitenden Konfiguration an.',
				radio: 'Aus dem Internet',
				configInvalid: 'Die geladene Konfiguration ist ungültig',
				urlInvalid: 'Die angegebene Internetadresse ist ungültig',
				loadingError: 'Aufgrund eines Netzwerkfehlers konnte die angegebenen Konfigurationsdatei nicht geladen werden. Bitte prüfen sie Ihre Internetverbindung und die Erreichbarkeit der Konfiguration. Sollte die Datei über einen direkten Aufruf im Browser erreichbar sein, liegt gegebenenfalls ein CORS-Problem vor. Wenden Sie sich in diesem Fall bitte an den Administrator des Servers auf dem sich die Konfiguration befindet oder laden sie die Datei manuell herunter und im Admintool wieder hoch.',
				urlPlaceholder: 'https://pfad/zur/config.json',
			},
			fromComputer: {
				title: 'Hochladen oder aus dem Internet laden',
				radio: 'Hochladen',
				versionLabel: 'Masterportal-Version',
				versionPlaceholderManual:
					'Bitte wählen Sie Ihre Masterportal-Version',
				versionPlaceholderAutomatic:
					'Wird automatisch aus Ihrer Datei ermittelt',
				actionUpload: 'Konfigurationsdatei auswählen',
				actionUploadExplanation:
					'Bitte wählen Sie die zu bearbeitende Konfiguration von Ihrer Festplatte',
				actionEdit: 'Konfiguration bearbeiten',
				upload: {
					identified: 'Die geladene Datei hat Version {{fileVersion}}. Sie wird mit der zugehörigen Masterportalversion verarbeitet.',
					identifiedMigrate: 'Die geladene Datei hat Version {{fileVersion}}. Sie haben ausgewählt sie auf die Masterportalversion {{version}} zu migrieren.',
					unsupported: 'Die Version {{fileVersion}} der geladenen Datei wird nicht unterstützt. Bitte wählen Sie oben eine Version aus, mit der diese Datei stattdessen bearbeitet werden soll.',
					unsupportedMigrate: 'Die Version {{fileVersion}} der geladenen Datei wird nicht unterstützt. Sie haben ausgewählt sie auf die Masterportalversion {{version}} zu migrieren.',
					unversioned: 'Die geladene Datei beinhaltet keine Versionsinformation. Bitte wählen Sie die Version Ihres Masterportals aus, um fortzufahren. Diese Version wird beim Speichern in der Datei hinterlegt.',
					invalid: 'Die geladene Datei scheint keine Masterportal-Konfigurationsdatei zu sein. Bitte überprüfen Sie Ihre Auswahl.',
					broken: 'Die geladene Datei beinhaltet kein valides JSON. Bitte überprüfen Sie Ihre hochgeladene Datei auf Syntaxfehler.',
					nothing: 'Bitte wählen Sie die zu bearbeitende Konfiguration von Ihrer Festplatte.',
				},
				errorUpload:
					'Die benötigten Daten für die Masterportalversion {{version}} konnten nicht bezogen werden.',
				errorNavigation:
					'Navigation fehlgeschlagen. Bitte nutzen Sie die Sidebar, um den nächsten Schritt auszuwählen.',
				errorGroupLayer:
					'Der enthaltene Layer "{{name}}" ist ein Gruppen-Layer. Diese werden derzeit nicht unterstützt.',
				errorNotFound:
					'Layer "{{name}}" (ID: {{id}}) konnte nicht gefunden werden.',
				successLoad:
					'Die ausgewählte Konfiguration ist in Masterportal Admin geladen.',
				invalidLayers:
					'Die ausgewählte Konfiguration ist geladen, es konnten allerdings nicht alle Layer gefunden werden. Bitte überprüfen Sie, ob die korrekte Kartendienste-Konfiguration geladen wurde.',
			},
			jsons: {
				errorUpload: 'Beim Laden ist ein Fehler ausgetreten: {{ error }}',
				errorFormat: 'Das Format der Daten ist ungültig.',
				missingProp: 'Die Eigenschaft "{{ property }}" wurde nicht gefunden',
				hintTitle: 'Fehlende Vorschaumöglichkeit',
				hint: 'Achtung: Wenn Sie eine Konfiguration aus dem Internet laden, stehen die Optionen "Vorschau" und "Veröffentlichung" unter dem Menüpunkt "Export" nicht mehr zur Verfügung!',
				dialogAbort: 'Abbrechen',
				dialogConfirm: 'Bestätigen',
				loadedFile: 'Aktuell geladene Datei',
				description: 'Geladene Elemente',
				layerConf: {
					title: 'Kartendienste-Konfiguration ändern',
					sideBarTitle: 'Kartendienste',
					typeError: 'Ungültiger Layer-Typ gefunden.',
					paragraph:
						'Laden Sie eine alternative Kartendienste-Konfiguration aus dem Internet.',
				},
				restConf: {
					title: 'REST-Dienste-Konfiguration ändern',
					sideBarTitle: 'REST-Dienste',
					paragraph:
						'Laden Sie eine alternative Konfiguration für REST-Dienste aus dem Internet.',
				},
				styleConf: {
					title: 'Style-Konfiguration ändern',
					sideBarTitle: 'Styles',
					paragraph:
						'Laden Sie eine alternative Style-Konfiguration aus dem Internet.',
				},
				fromURL: {
					title: 'Konfiguration aus dem Internet laden',
				},
				upload: {
					title: 'Lokale Konfigurationsdatei hochladen',
				},
			},
			masterportalDefault: {
				title: 'Default einer Masterportalversion laden',
				paragraph:
					'Wählen Sie bitte die Version des Masterportals, zu dem eine Konfiguration erstellt werden soll. Es werden nur Versionen gelistet, die von Masterportal Admin unterstützt werden.',
				versionLabel: 'Masterportal-Version',
				action: 'Ausgewählte Konfiguration verwenden',
				actionsmall: 'Verwenden',
			},
		},
		portalconfig: {},
		themenconfig: {
			title: 'Layer auswählen',
			sideBarTitle: 'Layer auswählen',
			paragraphs: {
				one:
					'Mit dieser Liste können Sie Layer auswählen, die im Portal zur Verfügung stehen werden.',
				two:
					'Auf der linken Seite sind alle angebotenen Layer aufgelistet. Für eine bessere Übersicht besteht die Möglichkeit einen Filter (nach Layernamen) anzuwenden.',
				three: {
					partOne:
						'Ziehen Sie die Layer per Drag&Drop auf die rechte Seite um sie im Masterportal verfügbar zu machen. Mit einem Klick auf das Karten-Symbol (',
					partTwo:
						') wird eine Vorschau des Layers angezeigt. Desweiteren kann mit Klick auf das Augen-Symbol (',
					partThree:
						') festgelegt werden, ob der entsprechende Layer im Portal initial sichtbar sein soll oder nicht.',
				},
				four: 'Über einen Doppelklick auf den Layer lässt sich außerdem dessen Name editieren. Der Name kann mit Enter bestätigt werden.',
				custom: {
					partOne: 'Mit einem Klick auf das Plus-Symbol (',
					partTwo: ') kann ein neuer Ordner erstellt werden. Dieser kann mit einem Klick auf das Mülltonnen-Symbol (',
					partThree: ') wieder entfernt werden. Mit dem  Symbol ',
					partFour: ' kann eingestellt werden, ob der Ordner anwählbar ist.',
				},
			},
			treeType: 'Baumtyp',
			treeTypes: {
				light: 'Flach',
				custom: 'Verschachtelt',
			},
			filterTitle: 'Layer suchen',
			availableLayers: 'Verfügbare Layer',
			chosenLayers: 'Ausgewählte Layer',
			chosenLayersAndFolders: 'Ausgewählte Layer und Ordnerstruktur',
			dragLayers:
				'Verfügbare Layer können durch ziehen an eine Stelle auf der rechten Seite im Masterportal eingebunden werden. Durch die Position in der Liste wird die Ebene des Layers festgelegt, wobei Layer weiter oben in der Liste weiter unten eingeordnete Layer überlagern / verdecken.',
			preview: 'Vorschau',
			visibility: 'Sichtbarkeit',
			moveLayer: {
				up: 'Hoch bewegen',
				down: 'Herunter bewegen',
			},
			overrideLayer: {
				title: 'Layer konfigurieren',
				paragraph: 'Diese Seite ermöglicht es, weitere Layer zu erstellen und zu konfigurieren.',
				wms: {
					title: 'WMS-Layer',
					name: 'Name des WMS-Dienstes',
					url: 'URL des WMS-Dienstes',
					version: 'WMS-Version',
					mpadminFeatures: 'Ausgewähltes Feature',
					layers: 'Feature-ID',
					layerAttribution: 'Layerattribution',
					minScale: 'Minimale Skalierung',
					maxScale: 'Maximale Skalierung',
					legendURL: 'Legende (URL)',
				},
				createAction: 'Anlegen',
				configAction: 'Konfigurieren',
				deleteAction: 'Entfernen',
			},
			groupLayer: {
				title: 'Gruppenlayer konfigurieren',
				paragraph: 'Ein Gruppenlayer ist ein Layer, in dem mehrere Layer so zusammengefasst werden, dass die Layer nur gemeinsam und nicht einzeln an- bzw. abwählbar sind. Die Gruppenlayer müssen in der Themenauswahl wie normale Layer eingebunden werden.',
				choose: 'Layer auswählen',
				name: 'Name des Gruppenlayers',
				createAction: 'Weiteren Gruppenlayer erstellen',
				deleteAction: 'Gruppenlayer löschen',
			},
			styleLayer: {
				title: 'Layer stylen',
				paragraph: 'Mit dieser Liste können Sie WFS-Layer gestalten.',
				selected: 'Ausgewählte WFS-Layer',
				name: 'Name',
				preview: 'Vorschau',
				styleId: 'Style-ID',
				experimentalType: 'Das Styling für diesen Layertypen wird vom Masterportal nicht vollständig unterstützt!',
			},
			gfiLayer: {
				title: 'GFI-Attribute selektieren',
				paragraph: 'Mit dieser Liste können Sie die Anzeige der GFI-Attribute konfigurieren. Um einzelne Attribute umzubenennen oder zu-/abzuwählen, muss die Option "Attributauswahl konfigurieren" ausgewählt werden.',
				customParagraph: 'Ob ein Attribut angezeigt wird oder nicht, zeigt das Symbol in der entsprechenden Zeile an. Ein Attribut kann eingeblendet werden, indem ein Titel eingestellt wird und abgewählt, indem der Titel entfernt wird.',
				selected: 'Ausgewählte Layer',
				attribute: 'Attribut',
				name: 'Titel',
				native: 'Standardkonfiguration des Layers übernehmen',
				showAll: 'Alle Attribute anzeigen',
				custom: 'Attributauswahl konfigurieren',
			},
		},
		export: {
			title: 'Export der erzeugten Konfiguration',
			sideBarTitle: 'Export',
			incompatibleVersion:
				'Die zu Beginn ausgewählte Version ({{ targetVersion }}) unterscheidet sich wesentlich von der Version des verknüpften Masterportals ({{ hostedMasterportalVersion }}). Die erstellte Konfiguration kann möglicherweise nicht korrekt angewendet werden.',
			paragraph:
				'Bitte wählen Sie aus folgenden Möglichkeiten zum Export der soeben erzeugten Konfiguration. Die Optionen Vorschau und Veröffentlichen eignen sich dabei insbesondere für einen schnellen Überblick, ob die Konfiguration den Vorstellungen entspricht, da hier keine eigene Masterportal-Instanz installiert werden muss. Entscheiden Sie sich für den Download der Konfigurationsdatei oder eines konfigurierten Portals, so müssen Sie das Masterportal selbstständig auf einem Server/Webspace installieren bzw. betreiben. Dafür bieten diese Optionen mehr Flexibilität was weitere Anpassungen betrifft.',
			instanceURL: 'URL einer passenden Masterportal-Instanz',
			preview: {
				title: 'Vorschau',
				paragraph:
					'Die Vorschaufunktion kann genutzt werden, um zu prüfen, wie die Karte mit aktueller Konfiguration aussieht. Hierzu wird Ihre Konfigurationsdatei auf den Server geladen. Der erzeugte Link ist dann bis zu 24 Stunden gültig. Danach wird die Konfiguration gelöscht.',
				previewUrl:
					'Unter folgender URL kann eine Vorschau der aktuellen Konfiguration abgerufen werden:',
				action: 'Vorschau erzeugen',
			},
			publish: {
				title: 'Veröffentlichung',
				paragraph:
					'Die Veröffentlichungsfunktion erlaubt das permanente Hosten einer Konfiguration. Hierzu wird Ihre Konfigurationsdatei unter einem festzulegenden Pfad auf den Server geladen. Im Gegensatz zur Vorschau-Option wird dabei die Konfiguration dauerhaft gespeichert.',
				configUrl: {
					partOne:
						'Die Konfiguration wurde gespeichert. Die erzeugte JSON-Datei kann unter ',
					partTwo:
						' abgerufen werden. Alternativ kann die Konfiguration unter folgender Adresse betrachtet werden:',
				},
				placeholder: 'MeinPortal',
				action: 'Konfiguration veröffentlichen',
				overwrite: 'Bestehende Konfiguration überschreiben',
			},
			downloadPortal: {
				title: 'Download einer konfigurierten Masterportal-Instanz',
				paragraph:
					'Download einer fertig konfigurierten Masterportal-Instanz, welche durch Kopieren auf einem Webspace veröffentlicht werden kann.',
				action: 'Download',
			},
			downloadConfig: {
				title: 'Download der zentralen Konfigurationsdatei',
				paragraph:
					'Die bearbeitete Konfigurationsdatei kann jederzeit heruntergeladen werden, um weitere Änderungen manuell durchzuführen oder damit ein separat laufendes Masterportal zu betreiben. Auch kann die JSON-Datei erneut zur weiteren Bearbeitung in das Admin-Tool eingeladen werden.',
				action: 'Download',
			},
			text: {
				title: 'Anzeige der zentralen Konfigurationsdatei',
				paragraph: 'Anzeige der zentralen Konfigurationsdatei im Browser',
			},
			quickPreview: {
				title: 'Schnellvorschau',
			},
			errors: {
				missingName:
					'Bitte eine Bezeichung für die Konfiguration angeben.',
				invalidName:
					'Ungültige Eingabe: Erlaubte Zeichen sind: Aa-Zz, 0-9, - sowie _.',
				invalidScheme:
					'Der Name muss folgendem Schema entsprechen: "MeinLandkreis_MeinPortal".',
				buildError:
					'Während des Bauens der Masterportal-Instanz ist ein Fehler aufgetreten.',
				publishError:
					'Bei der Veröffentlichung ist ein Fehler aufgetreten.',
			},
		},
	},
};
