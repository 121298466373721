import AStepper from '~generic/stepper/AStepper.vue';
import AStepperStep from '~generic/stepper/AStepperStep.vue';
import AStepperContent from '~generic/stepper/AStepperContent.vue';

import ATreeview from '~generic/treeview/ATreeview.vue';
import ATreeviewItem from '~generic/treeview/ATreeviewItem.vue';

export default {
	install: app => {
		/* eslint-disable vue/component-definition-name-casing */
		app.component('a-stepper', AStepper);
		app.component('a-stepper-step', AStepperStep);
		app.component('a-stepper-content', AStepperContent);

		app.component('a-treeview', ATreeview);
		app.component('a-treeview-item', ATreeviewItem);
		/* eslint-enable vue/component-definition-name-casing */
	},
};
