<script setup>
import { computed } from 'vue';
import { useAlertStore } from '@/store/alert';

/** Global loading indicator to be controlled by store interactions. May be controlled from anywhere on demand. */
const store = useAlertStore();
const value = computed(() => store.value);
const type = computed(() => store.type);
const content = computed(() => store.content);
const options = computed(() => store.options);
</script>

<template lang="pug">
v-alert(
	:model-value="value"
	:type="type"
	transition="scale-transition"
) {{ $t(content, options) }}
</template>

<style lang="scss" scoped>
@import '../globals.scss';

.v-alert {
	position: fixed;
	bottom: 4em;
	left: 50%;
	transform: translateX(-50%);
	z-index: 5;
}
</style>
