<script setup>
const props = defineProps({
	showNavButtons: {
		type: Boolean,
		default: false,
	},
});

import { inject, toRefs } from 'vue';
import TheNavigationButtons from '@/components/TheNavigationButtons.vue';

const { showNavButtons } = toRefs(props);
const title = inject('title');
</script>

<template lang="pug">
v-card.fillWidth(tile)
	div(v-if="showNavButtons")
		v-col
			TheNavigationButtons
		v-divider
	div.wrapper
		v-card-title
			h2 {{ $t(title) }}
		slot
	div(v-if="showNavButtons")
		v-divider.spaced-lower-divider
		v-col
			TheNavigationButtons
</template>

<style lang="scss" scoped>
@import '../globals.scss';
.wrapper {
	margin: 10px;
}

.fillWidth {
	width: 100%;
}

.spaced-lower-divider {
	margin-top: 1.5em;
}
</style>
