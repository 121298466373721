<script setup>
import { ref, computed } from 'vue';
import { useTranslation } from 'i18next-vue';
import { useGraphStore } from '@/store/graph';

const { i18next } = useTranslation();
const graph = useGraphStore();

const graphModified = computed(() => new Date(graph._lastModified));
const locale = computed(() => i18next.language === 'de' ? 'de-DE' : 'en-US');
const displayDate = computed(() => graphModified.value.toLocaleDateString(locale.value, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }));
const displayTime = computed(() => graphModified.value.toLocaleTimeString(locale.value, { hour: '2-digit', minute: '2-digit' }));

const HOUR = 60 * 60 * 1000;
const showDialog = ref(Boolean(graph._lastModified) && (new Date() - graphModified.value >= 10 * HOUR));
</script>

<template lang="pug">
v-dialog(v-model="showDialog" width="500" scrollable)
	v-card(tile)
		v-card-title.headline.grey.lighten-2(primary-title)
			| {{ $t('storage.title') }}
		v-card-text(v-html="$t('storage.paragraphs.one', { displayDate, displayTime, version: graph.version })")
		v-card-text(v-html="$t('storage.paragraphs.two')")
		v-divider
		v-card-actions
			v-spacer
			v-btn(color="primary" @click="showDialog = false")
				| {{ $t('storage.action') }}
</template>
