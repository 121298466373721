import { ref, computed } from 'vue';
import { defineStore } from 'pinia';

export const usePageLayoutStyleStore = defineStore('pageLayoutStyle', () => {
	const styleName = ref('');
	function setStyleName (name) {
		styleName.value = name;
	}

	const logoStyle = computed(() => {
		switch (styleName.value) {
			case 'overlay':
				return 'position: fixed; top: 0; left: 0; z-index: 4;';
			case 'default':
			default:
				return '';
		}
	});
	const footerStyle = computed(() => {
		switch (styleName.value) {
			case 'overlay':
				return 'position: fixed; bottom: 0; left: 0; right: 0; z-index: 4;';
			case 'default':
			default:
				return '';
		}
	});
	const overlayWindowStyle = computed(() => {
		switch (styleName.value) {
			case 'overlay':
				return 'display: block;';
			case 'default':
			default:
				return '';
		}
	});
	const isBodyHidden = computed(() => styleName.value === 'overlay');

	return {
		styleName,
		setStyleName,
		logoStyle,
		footerStyle,
		overlayWindowStyle,
		isBodyHidden,
	};
});
