import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import { useTranslation } from 'i18next-vue';
import { useConfigStore } from '@/store/config';
import { useLoadingStore } from '@/store/loading';
import { useAlertStore } from '@/store/alert';
import { renameLabels } from '@/lib/renameHelper';
import { useLocalConfig } from '@/lib/devModeFlag';
import deepCopy from '@/lib/deepCopy';
import resolvePath from '@/lib/resolvePath';

import axios from 'axios';
import { parseMarkdown } from '@masterportal/mpconfigparser';

const getUrl = {
	defaultConfig: v => `https://bitbucket.org/geowerkstatt-hamburg/masterportal/raw/${v}/portal/basic/config.json`,
	documentation: v => `https://bitbucket.org/geowerkstatt-hamburg/masterportal/raw/${v}/doc/config.json.md`,
	documentationDe: v => `https://bitbucket.org/geowerkstatt-hamburg/masterportal/raw/${v}/doc/config.json.de.md`,
};

if (useLocalConfig) {
	getUrl.defaultConfig = () => '/config.masterportalApi.json';
	getUrl.documentation = () => '/config.masterportalApi.json.md';
	getUrl.documentationDe = () => '/config.masterportalApi.json.de.md';
}

export const useGraphStore = defineStore('graph', () => {
	const configStore = useConfigStore();
	const loading = useLoadingStore();
	const alert = useAlertStore();
	const { i18next } = useTranslation();

	const defaultConfig = ref({});
	const config = ref({});
	const graph = ref({});
	const rawGraph = ref({});
	const version = ref(null);

	function mapDocumentation ({ data: loadedDocumentation }, documentationLanguage) {
		rawGraph.value[documentationLanguage] = loadedDocumentation;
		const parsedDocumentation = parseMarkdown(loadedDocumentation);
		const matrixKey = documentationLanguage.toUpperCase();
		if (configStore.admintoolConfig.renameKeys?.[matrixKey]) {
			Object.entries(configStore.admintoolConfig.renameKeys?.[matrixKey] ?? {}).forEach(([ key, value ]) => {
				if (parsedDocumentation.nodes[key]) {
					parsedDocumentation.nodes[key].label = value;
				}
				const keyParts = key.split('.');
				const lastKey = keyParts.pop();
				const prefixKey = keyParts.join('.');
				if (parsedDocumentation.nodes[prefixKey]?.outgoing[lastKey]) {
					parsedDocumentation.nodes[prefixKey].outgoing[lastKey].label = value;
				}
			});
		}
		if (configStore.admintoolConfig.renameMatrix?.[matrixKey]) {
			renameLabels(parsedDocumentation, configStore.admintoolConfig.renameMatrix[matrixKey]);
		}
		return parsedDocumentation;
	}

	async function fetchFromMasterportalVersion ({ version: loadedVersion, config: initialConfig }) {
		loading.register();
		try {
			await Promise.all([
				axios.get(getUrl.defaultConfig(loadedVersion)).then(({ data: fetchedDefaultConfig }) => ({
					defaultConfig: fetchedDefaultConfig,
					config: initialConfig || deepCopy(fetchedDefaultConfig),
				})),
				axios.get(getUrl.documentation(loadedVersion)).then(res => mapDocumentation(res, 'en')),
				axios.get(getUrl.documentationDe(loadedVersion)).then(res => mapDocumentation(res, 'de')),
			]).then(([ { defaultConfig: _defaultConfig, config: _config }, documentation, documentationDe ]) => {
				Object.assign(defaultConfig.value, _defaultConfig);
				Object.assign(config.value, _config);
				graph.value.en = documentation;
				graph.value.de = documentationDe;
			});
			version.value = loadedVersion;
		} catch (e) {
			console.error('Parsing documentation markdown failed.');
			console.error(e);
			alert.error({ content: 'errors.invalidDocumentation' });
		} finally {
			loading.unregister();
		}
	}

	function defaultConfigTarget (path) {
		return resolvePath(defaultConfig.value, path);
	}
	function configTarget (path) {
		return resolvePath(config.value, path);
	}

	return {
		defaultConfig,
		defaultConfigTarget,
		config,
		configTarget,
		graph: computed(() => graph.value[i18next.language]),
		fetchFromMasterportalVersion,
		version,

		_graph: computed({
			get: () => rawGraph.value,
			set: ({ de, en }) => {
				graph.value.de = mapDocumentation({ data: de }, 'de');
				graph.value.en = mapDocumentation({ data: en }, 'en');
			},
		}),
		_persistedState: [
			'defaultConfig',
			'config',
			'version',
			'_graph',
		],
	};
});
