<script setup>
import { useRoute, useRouter } from 'vue-router';
import { useChaptersStore } from '@/store/chapters';

const route = useRoute();
const router = useRouter();
const chapters = useChaptersStore();

function isHighlighted (it) {
	/* eslint-disable eqeqeq */ // for falsy values to be equal
	if (route.params.path) {
		return route.params.path == it;
	}
	if (route.params.type) {
		return route.params.type == it;
	}
	/* eslint-enable eqeqeq */
	return route.name === it;
}

function navigate (it) {
	router.push(it);
	window.scrollTo(0, 0);
}
</script>

<template lang="pug">
//- TODO: When v-stepper is available again, use it again (expected for vuetify 3.1.0)
a-stepper(:activeStep="chapters.activeStep" :steps="chapters.chapters.length")
	template(
		v-for="([ rootItem, childNodes ], index) of chapters.chapters"
	)
		a-stepper-step(
			:step="index + 1"
			:to="rootItem.route"
		) {{ $t(rootItem.label) }}
		a-stepper-content(
			:step="index + 1"
		)
			v-list(v-if="childNodes && childNodes.length")
				v-list-item(
					v-for="(childNode, childIndex) in childNodes"
					@click.stop="navigate(childNode.route)"
					ripple
				)
					v-list-item-title.wrap-text
						v-icon(:color="isHighlighted(childNode.chapterKey) ? 'info' : 'grey'" icon="edit")
						| {{ $t(childNode.label) }}
</template>

<style lang="scss" scoped>
.wrap-text {
	white-space: normal;
}
</style>
