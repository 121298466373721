import { ref, computed } from 'vue';
import { defineStore } from 'pinia';

export const useLoadingStore = defineStore('loading', () => {
	const registeredPromises = ref(0);
	const loading = computed(() => registeredPromises.value > 0);

	function register () {
		registeredPromises.value++;
	}
	function unregister () {
		if (registeredPromises.value > 0) {
			registeredPromises.value--;
		}
	}

	return {
		loading,
		register,
		unregister,
	};
});
