<script setup>
import { ref, provide, watch } from 'vue';
import { useRoute } from 'vue-router';
import TheMap from '@/components/TheMap.vue';
import TheStorageDialog from '@/components/TheStorageDialog.vue';
import TheLoadingIndicator from '@/components/TheLoadingIndicator.vue';
import TheLocaleSwitch from '@/components/TheLocaleSwitch.vue';
import TheHeader from '@/components/TheHeader.vue';
import TheBody from '@/components/TheBody.vue';
import TheNavigation from '@/components/TheNavigation.vue';
import TheExpertCheckbox from '@/components/TheExpertCheckbox.vue';
import PageLayout from '@/components/PageLayout.vue';
import TheAlert from '@/components/TheAlert.vue';
import TheFooter from '@/components/TheFooter.vue';
import { useConfigStore } from '@/store/config';
import { useGraphStore } from '@/store/graph';
import { useLayersStore } from '@/store/layers';

const route = useRoute();

const layers = useLayersStore();
layers.initializeDB();

const graph = useGraphStore(); // Keep this here to load config before(!) graph
const config = useConfigStore();
config.initialize();

const title = ref('');
watch(() => route.name, () => {
	title.value = route.meta.title;
}, { immediate: true });
provide('title', title);

function onDragJoin (target, e) {
	target.setAttribute('data-dragging', true);
}
function onDragLeave (target, e) {
	target.removeAttribute('data-dragging');
}
</script>

<template lang="pug">
div(
	@dragenter="onDragJoin($el, $event)"
	@dragover="onDragJoin($el, $event)"
	@dragleave="onDragLeave($el, $event)"
	@drop="onDragLeave($el, $event)"
)
	TheMap
	TheStorageDialog
	v-app
		TheLoadingIndicator
		TheLocaleSwitch
		TheHeader
		TheBody
			TheNavigation
			TheExpertCheckbox
			PageLayout(:showNavButtons="route.meta.showNavButtons")
				router-view
		TheAlert
		TheFooter
</template>

<style lang="scss">
@import './globals.scss';

*, *::before, *::after {
	// override vuetify default (Roboto) everywhere
	font-family: hamburg-sans;
}

html {
	// override vuetify base default (14px)
	font-size: 16px;
	text-rendering: optimizeLegibility;
	color: $hamburg-color;
}

.v-application {
	background-image: url('@images/hamburg-2539953_1920.jpg') !important;
	background-attachment: fixed;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

// override vuetify theme default colors in favour of Hamburg StyleGuide colors
.theme--light {
	.v-application {
		// vuetify default has #fafafa, which conflicts with header background
		background: $hamburg-grey;
	}

	.v-card, .hint {
		// slightly differs from vuetify (0,0,0,0.87)
		color: $hamburg-color;

		> .v-card__text {
			color: $hamburg-color;
		}
	}

	&.v-label, &.v-messages {
		color: $hamburg-color;
	}
}

ol {
	margin-left: 1em;
	> li {
		margin-left: 1em;
		margin-bottom: 0.3em;
	}
}
</style>
