import { createRouter, createWebHistory } from 'vue-router';
import { setBasePath } from '@/lib/basePath';
import { useConfigStore } from '@/store/config';
import { useChaptersStore } from '@/store/chapters';

import P404 from '@/pages/404.vue';

export default (pinia) => {
	const base = setBasePath();

	function checkExcludedChapter (chapterName) {
		const config = useConfigStore(pinia);
		const chapters = useChaptersStore(pinia);
		const excludedChapters = config.admintoolConfig?.excludedChapters;
		if (!excludedChapters) {
			return null;
		}
		const isExcluded = excludedChapters.find(ec => ec.toLowerCase() === chapterName.toLowerCase());
		if (isExcluded) {
			if (chapters.chapters[0]?.[0]) {
				// redirecting to first chapter
				return chapters.chapters[0][0].route;
			}
			return { name: '404' };
		}
		return null;
	}

	const routes = [
		{
			path: '/',
			name: 'Introduction',
			component: () => import('@/pages/Introduction.vue'),
			meta: {
				title: 'chapters.introduction.title',
				showNavButtons: true,
			},
			beforeEnter: () => checkExcludedChapter('introduction'),
		},
		{
			path: '/loadConfig',
			name: 'LoadConfig',
			component: () => import('@/pages/LoadConfig.vue'),
			meta: {
				title: 'chapters.loading.title',
				showNavButtons: true,
			},
			beforeEnter: () => checkExcludedChapter('loadconfig'),
		},
		{
			path: '/loadConfig/:path',
			name: 'LoadJSONFile',
			component: () => import('@/pages/LoadJSONFile.vue'),
			props: true,
			meta: {
				title: 'chapters.loading.jsons.title',
				showNavButtons: true,
			},
			// This endpoint is used for the subitems of the introduction chapter
			beforeEnter: (to) => checkExcludedChapter(to.params.path),
		},
		{
			path: '/editConfig/Themenconfig',
			name: 'SelectLayers',
			component: () => import('@/pages/SelectLayers.vue'),
			meta: {
				title: 'chapters.themenconfig.title',
				showNavButtons: true,
			},
			beforeEnter: () => checkExcludedChapter('themenconfig'),
		},
		{
			path: '/editConfig/Themenconfig.layerConfig',
			name: 'LayerOverride',
			component: () => import('@/pages/LayerOverride.vue'),
			meta: {
				title: 'chapters.themenconfig.overrideLayer.title',
				showNavButtons: true,
			},
			beforeEnter: () => checkExcludedChapter('themenconfig'),
		},
		{
			path: '/editConfig/Themenconfig.groupConfig',
			name: 'GroupConfig',
			component: () => import('@/pages/GroupConfig.vue'),
			meta: {
				title: 'chapters.themenconfig.groupLayer.title',
				showNavButtons: true,
			},
			beforeEnter: () => checkExcludedChapter('themenconfig.groupconfig'),
		},
		{
			path: '/editConfig/Themenconfig.styleConfig',
			name: 'StyleConfig',
			component: () => import('@/pages/StyleConfig.vue'),
			meta: {
				title: 'chapters.themenconfig.styleLayer.title',
				showNavButtons: true,
			},
			beforeEnter: () => checkExcludedChapter('themenconfig'),
		},
		{
			path: '/editConfig/Themenconfig.gfiConfig',
			name: 'GfiConfig',
			component: () => import('@/pages/GfiConfig.vue'),
			meta: {
				title: 'chapters.themenconfig.gfiLayer.title',
				showNavButtons: true,
			},
			beforeEnter: () => checkExcludedChapter('themenconfig'),
		},
		{
			path: '/editConfig/:path',
			name: 'EditConfig',
			component: () => import('@/pages/EditConfig.vue'),
			props: true,
			meta: {
				showNavButtons: true,
			},
			beforeEnter: (to) => checkExcludedChapter(to.params.path),
		},
		{
			path: '/previewAndPublish',
			name: 'PreviewAndPublish',
			component: () => import('@/pages/PreviewAndPublish.vue'),
			meta: {
				title: 'chapters.export.title',
				showNavButtons: true,
			},
			beforeEnter: () => checkExcludedChapter('export'),
		},
		// 404 has to be handled client-side since server delivers single-page application
		{
			path: '/404',
			name: '404',
			component: P404,
			meta: {
				title: 'errors.notFound.title',
			},
		},
		{
			path: '/:pathMatch(.*)*',
			redirect: {
				name: '404',
			},
		},
	];

	return createRouter({
		routes,
		history: createWebHistory(base),
	});
};
