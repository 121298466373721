<script setup>
/** Custom footer, mostly as described by StyleGuide. */
import { usePageLayoutStyleStore } from '@/store/pageLayoutStyle';

const pageLayoutStyle = usePageLayoutStyleStore();
</script>

<template lang="pug">
div(:style="pageLayoutStyle.footerStyle")
	.footer
		.bow
		img.hamburg-logo(src="@images/HH_Sonder-Logo_4C_negativ.png")
</template>

<style lang="scss" scoped>
@import '../globals.scss';

.footer {
	position: relative;
	width: 100%;
	height: $footer-height;
	min-height: $footer-height;
	background: $hamburg-blue;

	.bow {
		position: absolute;
		top: -37.5%;
		left: -40px;
		height: $bow-height;
		width: $bow-width + 40px;
		background: $hamburg-red;
		transform: skew(-30deg);
		border-bottom-right-radius: 7px;
	}

	.hamburg-logo {
		position: absolute;
		height: calc(100% - 1em);
		right: 0;
		margin: 0.5em 1em;
	}
}
</style>
