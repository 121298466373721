<script setup>
/** Custom Header to meet StyleGuide description; deviance: it's not centered */
import { computed } from 'vue';
import { usePageLayoutStyleStore } from '@/store/pageLayoutStyle';
import { getBasePath } from '@/lib/basePath';

// MP_ADMINTOOL_VERSION is defined in vite.config.js
// eslint-disable-next-line no-undef
const appVersion = MP_ADMINTOOL_VERSION;
const pageLayoutStyle = usePageLayoutStyleStore();
const logoUrl = computed(() => `${getBasePath()}/assets/images/masterportal_admin_logo.svg`);
</script>

<template lang="pug">
div.header
	h1(:style="pageLayoutStyle.logoStyle")
		img(:src="logoUrl")
		span.beta {{ appVersion }}
</template>

<style lang="scss" scoped>
@import '../globals.scss';

.header {
	display: flex;
	flex-direction: row;
	font-weight: normal;
	justify-content: space-between;
	line-height: 1;

	h1 {
		position: relative;
		padding: 6px;
		background-color: $hamburg-content-background;
		margin: 12px;
	}

	img {
		height: 75px;
	}

	.beta {
		position: absolute;
		top: 3px;
		right: 3px;
		background-color: $hamburg-blue;
		color: #FFFFFF;
		font-size: 8pt;
		// padding for text header block as defined in StyleGuide
		padding: 6pt 6pt 4pt 6pt;
		font-family: hamburg-sans-bold;
	}
}
</style>
