import '@/fonts.scss';

import { createApp } from 'vue';
import vuetify from '@/plugins/vuetify';
import genericComponents from '@/plugins/generic-components';
import pinia from '@/plugins/pinia';
import i18n from '@/plugins/i18n';

import App from '@/App.vue';
import routerCreator from '@/router';

const app = createApp(App);
app.use(vuetify);
app.use(genericComponents);
app.use(pinia);
app.use(i18n);
app.use(routerCreator(pinia));
app.mount('#app');

// TODO: Remove this when caniuse.com says it is not needed anymore
if (!window.crypto?.randomUUID) {
	if (!window.crypto) {
		/* eslint-disable-next-line no-global-assign */
		window.crypto = {};
	}
	window.crypto.randomUUID = () => `MPADMIN-UUID-${new Date().getTime()}-${Math.random().toString().substring(2)}`;
}
