<script setup>
import { computed } from 'vue';
import { useTranslation } from 'i18next-vue';

const { i18next } = useTranslation();

const availableLanguages = {
	de: 'Deutsch',
	en: 'English',
};
const availableLanguageItems = Object.entries(availableLanguages).map(([ value, title ]) => ({ value, title }));

const currentLanguage = computed({
	get: () => i18next.language,
	set: i18next.changeLanguage,
});
</script>

<template lang="pug">
div.locale-changer
	v-select(
		v-model="currentLanguage"
		:items="availableLanguageItems"
		:label="$t('controls.changeLanguage')"
		filled
	)
</template>

<style lang="scss" scoped>
.locale-changer {
	position: absolute;
	top: 2px;
	right: 3px;
	width: 7.5em;
}
</style>
