<script setup>
const props = defineProps({
	to: {
		type: Object,
		required: true,
	},
	step: {
		type: Number,
		required: true,
	},
});

import { inject } from 'vue';
import { useRouter } from 'vue-router';

const activeStep = inject('AStepper-activeStep');
const router = useRouter();

function navigate (it) {
	router.push(it);
	window.scrollTo(0, 0);
}
</script>

<template lang="pug">
v-list-item.mt-1.pa-4(
	@click="navigate(props.to)"
	ripple
	:class="{ active: props.step === activeStep }"
)
	// TODO
	template(v-slot:prepend)
		.step {{ props.step }}
	v-list-item-title.title
		slot
</template>

<style lang="scss" scoped>
.step {
	background-color: rgba(0, 0, 0, 0.38);
	color: #fff;
	align-items: center;
	border-radius: 50%;
	display: inline-flex;
	font-size: 0.75rem;
	justify-content: center;
	height: 24px;
	min-width: 24px;
	width: 24px;
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
	margin: 0 8px;
	margin-right: 12px;
}

.title {
	line-height: 1;
	white-space: normal;
}

.active {
	.step {
		background-color: rgb(var(--v-theme-info));
	}
	.title {
		text-shadow: 0 0 0 #000;
		color: black;
	}
}
</style>
