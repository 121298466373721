/**
 * Read the basePath from localStorage. If no basePath is found assume the base
 * is equal to 'root' and return an empty string.
 *
 * @returns {string} The base path or empty string
 */
export function getBasePath () {
	return window.localStorage.getItem('basePath') || '';
}

/**
 * Resolve the path of 'root' and save the result within localStorage for later use.
 * If Admintool isn't hosted at `<host>/` but at `<host>/someBase/` there is the
 * need to do some prefixing. `Base` refers to the pathname in the example above.
 *
 * It's necessary to write `basePath` into localStorage because `document.currentScript`
 * isn't available within the scope of callbacks and events but within the scope of the
 * script
 *
 * @returns {string} The base path or empty string
 */
export function setBasePath () {
	const urlPattern = /^https?:\/\/[^/]+(?<basePath>\/.+)?\/assets\/.+$/;
	const match = import.meta.url.match(urlPattern);
	if (match) {
		const { groups: { basePath } } = match;
		if (basePath) {
			window.localStorage.setItem('basePath', basePath);
			return basePath;
		}
	}
	window.localStorage.removeItem('basePath');
	return '';
}

if (import.meta.vitest) {
	// in-source testing to mock import.meta
	const { describe, it, expect, afterEach, vi } = import.meta.vitest;
	describe('setBasePath()', () => {
		afterEach(() => {
			vi.restoreAllMocks();
		});
		it('sets base path if URL indicates a base path', () => {
			const setter = vi.spyOn(Storage.prototype, 'setItem');
			vi.spyOn(import.meta, 'url', 'get').mockReturnValue('https://geo.dataport.de/mp-admintool/assets/index.ccc.js');
			expect(setBasePath()).toBe('/mp-admintool');
			expect(setter).toHaveBeenCalledWith('basePath', '/mp-admintool');
		});
		it('sets an empty (no) base path if there is none', () => {
			const clearer = vi.spyOn(Storage.prototype, 'removeItem');
			vi.spyOn(import.meta, 'url', 'get').mockReturnValue('https://geo.dataport.de/assets/index.ccc.js');
			expect(setBasePath()).toBe('');
			expect(clearer).toHaveBeenCalledWith('basePath');
		});
	});
}
