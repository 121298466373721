/** first supported version pre-separated like [major, minor, patch] as numbers */
const firstSupportedTag = [
	// v2.7.0 is the first bilingually documented version - required for multilingual form generation
	2,
	7,
	0,
];
/** list versions to blacklist here like "v2.4.0" if need be */
const blacklist = [];

/**
 * Returns true if version is same or above first supported version.
 * Reads arbitrary amount of someway separated integers from version string
 * and checks it against first supported tag.
 * "v.1.4.2" works just as "1/4/2" would, and so on.
 * @param {string} version version string like v1.2.3
 * @param {string[]} comparator pre-separated version to compare to
 * @returns {boolean} whether version is greater/equal to first supported tag
 */
export function versionGreaterOrEqualTo (version, comparator) {
	const re = /\d+/g;
	let match;
	let current;

	for (let i = 0; i < comparator.length; i++) {
		// get next number from string
		match = re.exec(version);
		// if no number fetched, it's implicitly 0 (e.g. v2.0 is interpreted v2.0.0)
		current = match ? match[0] : 0;
		if (current > comparator[i]) {
			return true;
		} else if (current < comparator[i]) {
			return false;
		}
		// else they are identical on this level -> continue
	}

	// if comparator is shorther than version, implicitly fine => 2.0.0.x is always greaterequal to [2, 0, 0]
	return true;
}

/**
 * Will return all tags with names that are greater/equal to minimum version that are not on the blacklist.
 * @param {object[]} tags as returned from bitbucket
 * @returns {object[]} filtered tags
 */
export default tags => tags
	.filter(tag => versionGreaterOrEqualTo(tag.name, firstSupportedTag))
	.filter(tag => !blacklist.includes(tag.name));
